

function getKeyPath(obj, key) {
  // Split the key into an array of keys
  if(!obj || !key) return;
  const keys = Array.isArray(key) ? key : key.split('.');
  
  // Start with the initial object
  let currentObj = obj;

  // Loop through all keys
  for(let i = 0; (i < keys.length) && currentObj; i++) {
      // If the key doesn't exist in the object, return undefined
      let key = keys[i];
      if(key === undefined) key = null; // uniform key
      let next = currentObj[key]
      // Move to the next level
      currentObj = next;
  }

  // Return the found value
  return currentObj;
}

function setKeyPath(obj, key, value) {
  // Split the key into an array of keys
  const keys = Array.isArray(key) ? key : key.split('.');

  // Start with the initial object
  let currentObj = obj;

  // Loop through all keys, except the last one
  for(let i = 0; i < keys.length - 1; i++) {
      // If the key doesn't exist in the object, create an empty object
      let key = keys[i];
      if(key === undefined) key = null; // uniform key
      
      let next = currentObj[key];
      if(next === undefined) {
          // Check if the next key is a number, initialize it as an array
          if(isNumber(key)){currentObj[key] = next = []}
          else{currentObj[key] = next = {};}
      }
      // Move to the next level
      currentObj = next;
  }
  // Set the value at the last key
  currentObj && (currentObj[keys[keys.length - 1]]= value);
  return value;
}


function isObject(val){
  return (
    typeof val === 'object'
    && !Array.isArray(val)
    && val !== null
  );
}

function isString(val){
  return typeof val === 'string' || val instanceof String;
}

function isNumber(value) {
  return typeof value === 'number';
}

function isFunction(value) {
  return typeof value === 'function';
}
function isEmptyObject(obj) {
    if(!obj) return true;
    var name;
    for (name in obj) {	
        if (obj.hasOwnProperty(name)) {
            return false;
        }
    }
    return true;
}
/* remove by value from array, from both directions inplace */
var removeByValue = function(arr, val, num, direction) {
  num = num || arr.length;
  direction = direction || 1;
  let search_from = direction === 1 ? 0 : arr.length - 1

  while(num-- > 0) {
    let i = direction === 1 ? arr.indexOf(val, search_from) : arr.lastIndexOf(val, search_from);
    if(i === -1) break;
    arr.splice(i, 1);
    search_from = direction === 1 ? i : i -1
  }
  return arr;
};


/******** Prototype Implementations ******/

const toTitleCase = function(str){
  return str?.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    }
  );
}

const hashCode = function(str) {
  var hash = 0,
    i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

const formatWithValues = function(str, kv) {
  return str.replace(/{([^}]*)}/g, function(match, key) {
    for(let _key of key.split("|")){
      let v = getKeyPath(kv, _key.trim().split("."));
      if(v) return v;
    }
    return "";
  });
}

const nsplit = function(str, char, limit) {
  let ret = [];
  let i = 0, k = 0, n = str.length;
  while(i < n){
    let i = str.indexOf(char, k);
    if(i === -1 || ret.length >= limit) break;
    ret.push(str.substring(k, i));
    k = i + 1;
  }
  if(k < n){
    ret.push(str.substring(k, n));
  }
  return ret;
}

String.prototype.toTitleCase = function(){return toTitleCase(this)};
String.prototype.hashCode = function(){return hashCode(this)};
String.prototype.formatWithValues = function(kv){return formatWithValues(this, kv)};
String.prototype.nsplit = function(char, limit){return nsplit(this, char, limit)};


const clearArray = function(arr) {
  while (arr.length) {
    arr.pop();
  }
};

function removeDuplicatesInPlace(arr, key_func){
  let check = {};
  let i = 0;
  let n = arr.length;
  while(i < n){
    let key = key_func ? key_func(arr[i]) : arr[i];
    if(check[key]){
      arr.splice(i, 1);
      n--;
    } else {
      check[key] = true;
      i++;
    }
  }  
}

Array.prototype.removeByValue = function(val, num, direction){return removeByValue(this, val, num, direction)};
Array.prototype.clear = function(){return clearArray(this)};
Array.prototype.removeDuplicatesInPlace = function(){return removeDuplicatesInPlace(this)};

function clearObject(obj){
  for (var member in obj) delete obj[member];
}

function last(array, condition){
  if(condition){
    for(let i = array.length - 1; i >= 0; i--){
      if(condition(array[i], i)){
        return array[i];
      }
    }
    return null;
  } else {
    return array && array[array.length - 1];
  }
}

function round(num, n){
  n = n || 2;
  let z = Math.pow(10, n);
  return Math.round(num * z) / z;
}

/*  basic data type functions + prototypes end */

function idToTitle(id){
  return id && toTitleCase(id.replace(/_/g, " "));
}


class LocalStorageCache{
  constructor(cache_name, max_keys){
    this.cache_name = cache_name || "cache_v3";
    this.cache = JSON.parse(window.localStorage.getItem(this.cache_name) || "{}");
    this.max_keys = max_keys;
  }

  get(key_path){
    return getKeyPath(this.cache, key_path);
  }

  set(key_path, val){
    setKeyPath(this.cache, key_path, val);
    if(this.max_keys){ // clean up cache
      const keys = Object.keys(this.cache);
      while(keys.length > this.max_keys){
        delete this.cache[keys.shift()];
      }
    }
    window.localStorage.setItem(this.cache_name, JSON.stringify(this.cache));
  }
}

function swap(arr, i , j){
  let temp = arr[i];
  arr[i] = arr[j];
  arr[j] = temp;
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function popKey(obj, key){
  if(!obj) return;
  let val = obj[key];
  delete obj[key];
  return val;
}

function setLocalStorage(key, _v){
	if(_v === undefined || _v === null){
		return window.localStorage?.removeItem(key);
	}
	_v = isString(_v) ? _v : JSON.stringify(_v); //stringify v
	window.localStorage?.setItem(key, _v);
}

function getLocalStorage(key){
	let value = window.localStorage?.getItem(key);
	if(
		value 
		&& (
			value.startsWith("{")
			|| value.startsWith("[")
		)
	){
		try{
			value = JSON.parse(value);
		} catch(e){
			value = null;
		}
	}
	return value;
}

function generateTabUID() {
  let uid = window.sessionStorage?.getItem('tabUID');
  if (!uid) {
      uid = new Date().getTime().toString();
      window.sessionStorage?.setItem('tabUID', uid);
  }
  return uid;
}

function len(obj){
	if(!obj) return 0;
	if(Array.isArray(obj)){ return obj.length; }
	if(isObject(obj)){
		let ret = 0;
		for(let _ in obj){
			ret++;
		}
		return ret;		
	}
  if(isString(obj)){
    return obj.length;
  }
	return 1;	
}


export {
  isEmptyObject, removeByValue, last, round, clearObject, idToTitle,
  LocalStorageCache, isString, isObject, isNumber, isFunction,
  getKeyPath, setKeyPath, dataURLtoFile, hashCode, swap, setLocalStorage, getLocalStorage, 
  generateTabUID, toTitleCase, formatWithValues, nsplit, popKey, removeDuplicatesInPlace, len
};
