import React, { useEffect, useRef, useState } from 'react'
import { GenericException } from "./ui/errors";
import { loadScript, getRandomColor, useRerender } from "./utils/common";
import axios from 'axios';
import { DateView } from './ui/date';
import { Popup } from './ui/popups';
import { EmptyView, LoadingView } from './ui/status';
import { last } from './utils';
import { addScrolledToBottomEventListener, removeScrolledToBottomEventListener } from './utils/scrolling';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { currencyToSymbol } from './utils/common';


function StripeCheckout({publishable_api_key, client_secret, return_url}){

    const ctx = useRef({}).current;
    const rerender = useRerender();
    useEffect(() => {
        loadScript(`https://js.stripe.com/v3/`).then(async () => {
            const stripe = ctx.stripe = window.Stripe(publishable_api_key || StripeCheckout.publishable_api_key);

            const appearance = {theme: 'stripe'};
            const elements = ctx.elements = stripe.elements({ appearance, "clientSecret": client_secret });
            const paymentElement = ctx.paymentElement = elements.create(
                "payment", 
                {"layout": "tabs"}
            );
            ctx.el && paymentElement.mount(ctx.el);
        });
        return () => {
            ctx.paymentElement?.unmount();
        }
    }, []);

    async function confirmPayment(){
        if(ctx.is_loading) return;
        ctx.is_loading = true;

        const resp = await ctx.stripe.confirmPayment({
            elements: ctx.elements,
            confirmParams: {
                return_url: return_url || (window.location.origin + "/afterpayment/stripe")
            },
        })
        ctx.is_loading = false;
        resp.error
            ?   ctx.error_message= resp.error.message
            :   ctx.success= true;
        rerender();
    }

    if(ctx.is_loading) return <LoadingView title={"Confirming Payment"} height={"400px"} />
    if(ctx.success) return <EmptyView title="Payment was successful. You can close this and continue" height={500}/>
    return <div>
        {ctx.error_message && <div className='w3-padding w3-red'>{ctx.error_message}</div>}
        <div ref={(el) => {if(el) {ctx.el= el; ctx.paymentElement?.mount(el);}}} />
        <div className="w3-right-align">
            <div className="w3-button w3-black w3-round" onClick={confirmPayment}>Confirm</div>
        </div>
    </div>
}

function UserWallet({wallet}){
    const rerender = wallet.rerender = useRerender();
    return (
        <div key={`${wallet.wallet_for}${wallet.wallet_currency}`} className="w3-flex">
            <div>
                <div onClick={() => Popup.show("Ledger Entries", <WalletLedgerEntries wallet={wallet} />)}>
                    Your <span style={{"color": getRandomColor(wallet.wallet_for)}}>
                        {wallet.wallet_for}
                    </span> wallet has <span className="w3-text-green w3-text-large">{wallet.wallet_amount} {currencyToSymbol(wallet.wallet_currency)}</span>
                </div>
                <div>
                    {wallet.data?.stripe_connected_account_charge_enabled 
                        ? <div className='w3-tag w3-green'>Stripe Connected</div> 
                        : null
                    }
                </div>
            </div>
            {
                UserWallet.Options
                ?   <FontAwesomeIcon icon={faEllipsisV} className="w3-margin-left-auto w3-padding-sides-8"
                        onClick={(evt) => Popup.showContextMenu(evt.target, <UserWallet.Options wallet={wallet} />)}
                    />
                :   null
            }
        </div>
    );
}



/** Payments */
function WalletLedgerEntries({wallet, endpoint="/api/wallet/ledger"}){
    const [ledger_entries, setLedgerEntries] = useState(null);
    const [has_more, setHasMore] = useState(true);
    const [is_loading, setIsLoading] = useState(null);
    const scrollable_el_ref = useRef(null);
  
    const fetchPreviousLedger = () => {
        if(!has_more || is_loading) {
            return;
        }
        setIsLoading(true);
        axios.get(WalletLedgerEntries.endpoint || "/api/wallet/ledger", {
            "params": {
                "wallet_user_id": wallet.user_id,
                "wallet_for": wallet.wallet_for,
                "wallet_currency": wallet.wallet_currency,
                "before": ledger_entries && last(ledger_entries)?.created_at
            }
        }).then(
            (resp) => {
                if(resp.data.errors){
                  Popup.show("Error", <GenericException ex={resp.data.errors} />);
                  return;
                }
                setLedgerEntries([...(ledger_entries || []), ...(resp.data?.ledger_entries || [])]);
                setHasMore(resp.data.has_more);
            }
        ).finally(
          () => {
            setIsLoading(false);
          }
        );
    }
  
    useEffect(
        () => {
            fetchPreviousLedger();
        }, []
    );
  
    useEffect(
        () => {
            if(scrollable_el_ref.current){
                const el = scrollable_el_ref.current;
                addScrolledToBottomEventListener(el, fetchPreviousLedger);        
                return () => removeScrolledToBottomEventListener(
                    el, fetchPreviousLedger
                );
            }
        },
        [scrollable_el_ref.current]
    );
    
    if(!ledger_entries){
        return <EmptyView title="loading..." height="200px" />
    }
  
    return (
        <div className="tw-px-4 w3-scroll" ref={scrollable_el_ref}>
            <p className='tw-text-md tw-text-black'>Transaction History</p>
            <div className='tw-border tw-rounded-xl tw-mt-2'>
                <div className='tw-flex tw-text-center tw-text-xs tw-font-bold tw-py-4 tw-border-b'>
                    {/* <div className="w3-col s3">From Balance</div> */}
                    <div className="tw-flex-1">Title</div>
                    <div className="tw-flex-1">Date</div>
                    <div className="tw-flex-1">Transaction</div>
                    <div className="tw-flex-1">Balance</div>
                </div>
                <div className='w3-list tw-mt-2'>
                    {
                        ledger_entries.map(
                            (entry) => {
                                return <div className="tw-flex tw-items-center tw-text-center tw-text-xs tw-p-2 tw-border-b" key={entry.ref_id}>
                                    {/* <div className="w3-col s3">{entry.change_from}</div> */}
                                    <div className="tw-flex-1 tw-text-xs tw-text-black-500 tw-capitalize">
                                        {entry.ref_type.replaceAll("_", " ")}
                                        <div className='tw-text-xxs tw-text-black-500'>
                                            {entry.ref_id}
                                        </div>
                                    </div>
                                    <div className="tw-flex-1 tw-text-xs tw-text-black-500">
                                        <DateView className="w3-text-black" millis={entry.created_at} />
                                    </div>
                                    <div className={`tw-flex-1 ${entry.diff.split(" ")[0] < 0 ? "tw-text-red-500" : "tw-text-green-500"}`} >{entry.diff}</div>
                                    <div className="tw-flex-1 tw-text-black">{entry.change_to}</div>
                                </div>
                            }
                        )
                    }
                </div>
            </div>
            {
                !has_more && <div className="w3-center w3-row w3-bold w3-padding">No More Entries</div>
            }
      </div>
    )
}

export {StripeCheckout, WalletLedgerEntries, UserWallet}
