import { ConnectButton } from '360dialog-connect-button';
import axios from 'axios';
import { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useSearchParams } from 'react-router-dom';

import { useCurrentUser } from 'base/app';
import EventTracker, { FREE_TRIAL_EVENTS } from 'base/eventsTracker';
import { GenericException } from 'base/ui/errors';
import { Popup } from 'base/ui/popups';
import { loadFbConnect } from 'base/utils/common';
import { broadcaster } from 'base/utils/events';

import AILogo from "../images/ic_ai_purple.svg";
import SukhibaLogo from "../images/ic_logo_sukhiba.svg";
import ClientLogo1 from "../images/landing/client_logos/ic_client_1.png";
import ClientLogo10 from "../images/landing/client_logos/ic_client_10.png";
import ClientLogo11 from "../images/landing/client_logos/ic_client_11.png";
import ClientLogo12 from "../images/landing/client_logos/ic_client_12.png";
import ClientLogo13 from "../images/landing/client_logos/ic_client_13.png";
import ClientLogo14 from "../images/landing/client_logos/ic_client_14.png";
import ClientLogo2 from "../images/landing/client_logos/ic_client_2.png";
import ClientLogo3 from "../images/landing/client_logos/ic_client_3.png";
import ClientLogo4 from "../images/landing/client_logos/ic_client_4.png";
import ClientLogo5 from "../images/landing/client_logos/ic_client_5.png";
import ClientLogo6 from "../images/landing/client_logos/ic_client_6.png";
import ClientLogo7 from "../images/landing/client_logos/ic_client_7.png";
import ClientLogo8 from "../images/landing/client_logos/ic_client_8.png";
import ClientLogo9 from "../images/landing/client_logos/ic_client_9.png";
import MBPLogo from "../images/landing/MBPBadge-2.webp";
import MetaLogo from "../images/landing/meta_symbol.webp";
import bgPage1 from "../images/landing/page1_bg.svg";
import ImgHome from "../images/landing/wa-connect/wa-main.webp";
import { LogoScroller } from '../logoScroller';


const client_logos = [
  ClientLogo1, ClientLogo2, ClientLogo3, ClientLogo4, ClientLogo5, ClientLogo6, ClientLogo7,
  ClientLogo8, ClientLogo9, ClientLogo10, ClientLogo11, ClientLogo12, ClientLogo13, ClientLogo14
].map((image, i) => ({
  id: i,
  image
}));


function D360ConnectButton({params}) {
  const user = useCurrentUser(); // broadcasts everywhere
  const D360_PARTNER_ID = "nSqUc4PA"

  const afterD360PhoneNumberConnected = (d360_data) => {
    var ui_block = Popup.blockUi(
      "Please wait while we connect your phone number.",
      {"remove_others": true}
    );
  
    axios.post(
      `/api/admin/d360/${D360_PARTNER_ID}/signup`,
      {
        "client_id": d360_data.client,
        "channel_ids": d360_data.channels,
        "revoked_channel_ids": d360_data.revokedChannels,
        ...(params || {})
      }
    ).then(
      (resp) => {
        if(resp.data.errors){
          GenericException.showPopup(resp.data.errors);
          return;
        }
        if(resp.data.hints?.wait){
          setTimeout(() => afterD360PhoneNumberConnected(D360_PARTNER_ID, d360_data), 5000);
          return;
        }
        window.location.href = `/admin/${resp.data.delivery_org._id}`;
      }
    ).catch(
      (err) => {
        GenericException.showPopup(err);
        ui_block.remove();
      }
    );
  }



  return (
    <div>
      {user ? (
        <ConnectButton
          partnerId={D360_PARTNER_ID}
          callback={(data) => afterD360PhoneNumberConnected(D360_PARTNER_ID, data)}
          className="tw-bg-gray-200 tw-px-4 tw-py-2 tw-rounded" // <-- Insert your own styles via className definition or through inline styling
          label="Create your WhatsApp Business Account"
          queryParameters={{
            redirect_url: window.location.href,
          }}
        />
      ) : (
        <button
          className="360dialog-connect-button tw-bg-gray-200 tw-px-4 tw-py-2 tw-rounded"
          onClick={() => broadcaster.broadcast_event("error:need_user")}
          type='button'
        >
          Create with Dialog360 (Partner + 20$ extra per month)
        </button>
      )}
    </div>
  );
}

const FB_APP_ID = "465583072376947";

function MetaWhatsappConnectButton({children, params, className}) {
  const user = useCurrentUser(); // broadcasts everywhere
  const [search_params, setSearchParams] = useSearchParams();
  const ctx = useRef({}).current
  if (search_params.has("source")) {
    params["source"] = search_params.get('source');
  }

  function validateWooPluginSetup(org_id) {
    if (ctx.is_woo_plugin_setting) return
    const client_store = search_params.get("client_store")

    ctx.is_woo_plugin_setting = true
    axios.post(`/api/admin/org/${org_id}/settings?action=generate_system_token`)
      .then((resp) => {
        if (resp.data.errors) {
          return
        }
        const auth_key = resp.data.auth_key;
        window.open(`${client_store}/wp-json/sukhiba/v1/api-key?sukhiba_api_key=${auth_key}&delivery_org_id=${org_id}`, "_self")
        search_params.delete("source")
        search_params.delete("client_store")
        setSearchParams(search_params)
        ctx.is_woo_plugin_setting = false
      })
  }

  async function connectWhatsapp() {
    if(!user){
      broadcaster.broadcast_event("error:need_user");
      return;
    }

    // complete embedded signup and send the waba token along with delivery_org_id
    await loadFbConnect(FB_APP_ID);
    const FB = window.FB;

    FB.login(
      (response) => {
        if (!response.authResponse?.code) {
          return;  // user cancelled flow
        }
        const ui_block = Popup.blockUi("please wait...It may take upto 2 minutes. Don't close or refresh window.");
        axios.post(
          `/api/admin/waba/signup`,
          {
            "code": response.authResponse.code, 
            "fb_app_id": FB_APP_ID,
            ...(params || {})
          }
        ).then(
          (resp) => {
            if(resp.data.errors){
              GenericException.showPopup(resp.data.errors, "Error");
              return;
            }
            // todo change this to a better callback based approach
            if (params.source === "woo-plugin" && search_params?.has("client_store")) {
              validateWooPluginSetup(resp.data.delivery_org._id)
            } else {
              params.is_trial_number && EventTracker.track(FREE_TRIAL_EVENTS.UPDATE_NUMBER_SUCCESS);
              window.location.href = `/admin/${resp.data.delivery_org._id}`;
            }
          }
        ).finally(
          () => ui_block.remove()
        );        
      },
      {
        "config_id": "612076325098322",
        "response_type": 'code',
        "override_default_response_type": true,
        "extras": {
          "setup": {},
          "featureType": '',
          "sessionInfoVersion": '3',
        }
      }
    );
  }

  return (
    <button
      type='button'
      className={`font-figtree tw-text-[20px] tw-rounded-full tw-bg-[#0081FB] tw-px-4 tw-text-white tw-py-2 tw-my-1 max-md:tw-w-full tw-font-medium tw-flex tw-flex-row tw-items-center tw-gap-2.5 tw-justify-center ${className}`}
      onClick={() => {
        params.is_trial_number && EventTracker.track(FREE_TRIAL_EVENTS.UPDATE_NUMBER_INITIATED);
        connectWhatsapp();
      }}>
      <img src={MetaLogo} className='tw-h-[17px]' alt="meta-logo" />
      {children || "Join with Meta"}
    </button>
  );
}



const WaConnect = ({dorg_id}) => {
  const params = {"delivery_org_id": dorg_id};

  return (
    <>
    <div style={{ backgroundImage: `url(${  bgPage1 })`, backgroundSize: 'cover', minWidth: "100%", minHeight: "100%", overflow: "hidden", position: "fixed" }} />
    <div className="font-figtree tw-scroll-smooth tw-scrollbar-none">
      <div
        style={{ backgroundImage: `url(${  bgPage1  })`, backgroundSize: 'cover' }}
        className="tw-sticky tw-top-0 tw-flex tw-px-8 tw-py-6 lg:tw-px-16 tw-text-black tw-justify-between tw-items-center max-sm:tw-z-[4]">
        <Link to="/home">
          <div className="tw-cursor-pointer">
            <img src={SukhibaLogo} className="tw-h-[33px]" alt='sukhiba_logo' />
          </div>
        </Link>
      </div>
      <div
        id="home"
        className="tw-relative tw-px-6 tw-py-6  sm:tw-flex md:tw-justify-center max-lg:tw-gap-8 lg:tw-gap-16 tw-mb-16">
        <div className="tw-text-landing-black tw-font-semibold">
          <div className="tw-text-[36px] lg:tw-text-[48px] xl:tw-text-[60px] tw-leading-tight">
            <p className="">Welcome to</p>
            <p className="tw-text-landing-green">Sukhiba Connect</p>
          </div>
          <div className="sm:tw-whitespace-pre tw-mt-4 tw-font-light tw-text-[15px] md:tw-text-[15px]  sm:tw-text-[18px] lg:tw-text-[22px]">{`We're truly excited about the opportunity to collaborate\nwith you and embark on this journey together!`}
          </div>
          <div className="tw-mt-8 md:tw-mt-4 tw-bg-[#9746fe]/[0.1] tw-rounded tw-flex tw-gap-1 tw-items-center max-md:tw-justify-center tw-w-max tw-py-2 tw-px-3  max-md:tw-w-full">
            <img src={AILogo} className="tw-w-3" alt='ai-logo' />
            <p className="tw-text-[#3A20D5] tw-text-[14px] md:tw-text-[18px] tw-font-medium">AI Powered Assistance to Help you Scale</p>
          </div>
          <LazyLoadImage src={MBPLogo} className="tw-h-[68px] tw-mt-3" />
          <MetaWhatsappConnectButton params={params} />
          <div className="tw-mt-8 tw-flex tw-gap-8 md:tw-gap-4 lg:tw-gap-8 tw-items-center max-md:tw-justify-between">
              <div className="tw-text-[#615F5C] tw-drop-shadow-xl md:tw-whitespace-pre max-md:tw-text-center tw-text-[18px] md:tw-text-[24px] tw-font-semibold">Trusted by 
                <span className="tw-text-landing-green tw-mx-2.5">150+ companies</span>
                globally to
                <div className="tw-text-[#615F5C]">scale their operations</div>
              </div>
          </div>
        </div>

        <div className="max-sm:tw-max-w-[90%] md:tw-max-w-[38%]  2xl:tw-max-w-[30%] tw-display-block xl:tw--mt-4 max-lg:tw-mt-8 max-lg:tw-flex max-lg:tw-justify-center">
          <LazyLoadImage src={ImgHome} className="tw-object-scale-down" />
        </div>
      </div>
      <div className="tw-bg-[#093232] tw-p-2 tw-absolute tw-left-0 tw-right-0 tw-bottom-0">
        <LogoScroller images={client_logos} speed={15000} />
      </div>
    </div>
    </>
  )
}

export default WaConnect
export {MetaWhatsappConnectButton};
