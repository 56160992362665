import React from "react";

const WooPluginUri = () => {
  return (
    <div className="tw-max-w-3xl tw-mx-auto tw-my-5 tw-p-5 tw-bg-white tw-shadow-lg">
      {/* Plugin Header */}
      <header className="tw-mb-5">
        <h1 className="tw-text-3xl tw-text-blue-600 tw-font-bold">Sukhiba WhatsApp Integration</h1>
        <p className="tw-mb-3">
          Enhance your WooCommerce store&apos;s customer experience with seamless WhatsApp notifications and customer support.
        </p>
        <a
          href="https://drive.google.com/file/d/14INpx7LY4xMDks6OhkVF4j0GMpEgzKiP/view?usp=sharing"
          className="tw-inline-block tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded tw-text-center tw-no-underline hover:tw-bg-blue-700"
        >
          Download Now
        </a>
      </header>

      {/* Plugin Overview */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Overview</h2>
        <p className="tw-mb-3">
        The <strong> Sukhiba WhatsApp Integration  </strong> plugin allows you to send automated WhatsApp notifications for order confirmations, cancellations, and refunds. It also enables customers to contact support directly via WhatsApp.
        </p>
      </section>

      {/* Features */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Features</h2>
        <ul className="tw-list-disc tw-list-inside">
          <li className="tw-mb-2">Send automated WhatsApp notifications when a new order is placed by a customer.</li>
          <li className="tw-mb-2">Send automated WhatsApp notifications when an order is cancelled.</li>
          <li className="tw-mb-2">Send automated WhatsApp notifications when an order is refunded.</li>
          <li className="tw-mb-2">Send notification on whatsapp about current status of an order from <strong> Send Whatsapp Notification</strong> option from <strong>order actions</strong> in order details page</li>
          <li className="tw-mb-2">Plugin provides ability to toggle the settings to enable/disable whatsapp notification to each status</li>
          <li className="tw-mb-2">Enable customers to contact support via WhatsApp.</li>
        </ul>
      </section>

      {/* Installation */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Installation</h2>
        <ol className="tw-list-decimal tw-list-inside">
          <li className="tw-mb-2">
            Download the plugin from the{' '}
            <a href="https://drive.google.com/file/d/1DKE2FVb31RZ5Cf-myM389-r9ba-SraSb/view?usp=sharing" className="tw-text-blue-600 hover:tw-underline">
              download page
            </a>
            .
          </li>
          <li className="tw-mb-2">
            Upload zip file to your woocoomerce store by clicking on Plugins &gt; Add New Plugin. Then click on Upload button.
          </li>
          <li className="tw-mb-2">
            Click on &apos;Activate plugin&apos; button.
          </li>
          <li className="tw-mb-2">
            Go to <strong>WooCommerce &gt; Settings &gt; WhatsApp Notifications</strong> to configure
            the plugin.
          </li>
        </ol>
      </section>

      {/* FAQs */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Frequently Asked Questions</h2>
        <h3 className="tw-text-xl tw-font-semibold tw-mt-3">What versions of WooCommerce are supported?</h3>
        <p className="tw-mb-3">This plugin is compatible with WooCommerce 6.0 and above.</p>

        <h3 className="tw-text-xl tw-font-semibold tw-mt-3">Can I customize the notification messages?</h3>
        <p className="tw-mb-3">
          Yes, you can customize the plugin by reaching out to Sukhiba&apos;s support team.
        </p>

        <h3 className="tw-text-xl tw-font-semibold tw-mt-3">Is this plugin free?</h3>
        <p className="tw-mb-3">
          Yes. The pluging is free to use. But you also need to pay Meta for whatsapp messages which are sent by you to your customers.
        </p>
      </section>

      {/* Support */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Support</h2>
        <p className="tw-mb-3">
          For support, you reachout to us on whatsapp {' '}
          <a href='https://api.whatsapp.com/send?phone=254794476965&text=Hey%20i%20want%20to%20know%20more%20about%20Sukhiba' className="tw-text-blue-600 hover:tw-underline"> 
            here
          </a> {' '}
          or contact us at{' '}
          <a href="mailto:uday@sukhiba.com" className="tw-text-blue-600 hover:tw-underline">
            support@example.com
          </a> {' '}
          or you can request demo{' '}
          <a href="https://lite.sukhiba.com/contactus" className="tw-text-blue-600 hover:tw-underline">
            contact us page.
          </a>
        </p>
      </section>

      {/* Changelog */}
      <section className="tw-mt-5">
        <h2 className="tw-text-2xl tw-text-blue-600 tw-font-bold">Changelog</h2>
        <h3 className="tw-text-xl tw-font-semibold tw-mt-3">Version 1.0.0</h3>
        <ul className="tw-list-disc tw-list-inside">
          <li className="tw-mb-2">Initial release of the plugin.</li>
        </ul>
      </section>

      {/* Footer */}
      <footer className="tw-mt-5 tw-text-center">
        <p className="tw-mb-3">&copy; 2025 Sukhiba. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WooPluginUri;