import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GenericException } from "base/ui/errors";
import { SelectTags } from "base/ui/misc";
import { Popup } from "base/ui/popups";
import SelectAddress from 'base/select_address';
import { PHONE_NUMBER_CODES } from "../../../constants";
import { useRerender } from "base/utils/common";

const DEFAULT_COUNTRY_PHONE_CODE = '254';

function AddNewCustomer({delivery_route, onSave, user}){
    const ctx = useRef({}).current;
    
    const [selected_tags, setSelectedTags] = useState(null);
    const [name, setName] = useState(user?.name || '');
    const [phone_number, setPhoneNumber] = useState(user?.phone_number || '')
    const [form_submitted, setFormSubmitted] = useState(false);

    const area_tags = [...new Set(delivery_route.delivery_points?.map((cp) => cp.area).filter((area) => area))];
    const non_area_tags = [...new Set(delivery_route.data?.additional_tags?.map(tag => tag?.trim()).filter((tag) => tag))];
    const rerender = useRerender()

    useEffect(() => {setSelectedTags(user?.tags || []);}, [user])

    useEffect(() => {
      axios.get("/user/location").then(
        (resp) => {
          if (resp.data) {
            ctx.country_phone_code = resp.data.phone_code
            rerender()
          }
        }
      )
    }, [])

    const doAddCustomer = () => {
      if(ctx.in_progress) return;
      setFormSubmitted(true);
      if (!name || !phone_number) return;

      ctx.in_progress = true;
      const payload = {
        "name": name,
        "phone_number": phone_number,
        "country_phone_code": ctx.country_phone_code || DEFAULT_COUNTRY_PHONE_CODE,
        "tags": ctx.selected_tags,
        "address": ctx.address || null,
        "action": user ? 'update' : 'create',
        "do_not_notify": ctx.do_not_notify
      };
      if (user) {
        payload.user_id = user._id
      }
      axios.post(
        `/api/admin/user/${delivery_route._id}`,
        payload
      ).then(
        (resp) => {
          if(resp.data.errors){
            Popup.show("Errors", <GenericException ex={resp.data.errors} />)
            return;
          }
          const updated_user = resp.data.updated_user || resp.data.added_user;
          updated_user && onSave(updated_user);
        }).catch((err) => {
        Popup.show("Errors", <GenericException ex={err} />)
      }).finally(
        () => ctx.in_progress = false
      );
    };

    

    const setAddressWithTags = (_address) => {
      ctx.address = {
        'street_and_house': _address.formatted_address,
        'lat_long': _address.geometry ? [_address.geometry.location.lat(), _address.geometry.location.lng()] : null
      }
      const selected_tags = area_tags.filter(area => {
        let area_name = area.split(/[.,*-\s]/)?.[0];
        return _address.formatted_address?.toLowerCase().includes(area_name?.toLowerCase());
      }) 
      setSelectedTags(selected_tags);
    }

    return (
        <div className='w3-row w3-padding'>
            <div className='tw-text-sm tw-text-gray-600'>Name of the customer</div>
            <div className="tw-mb-4">
              <input type="text"  className='w3-input'
                placeholder='Enter Name here'
                defaultValue={user?.name || ''}
                required={true}
                onChange={(evt) => setName(evt.target.value)}
              />
              {form_submitted && !name ? <div className="tw-text-xs tw-text-red-500 tw-mt-1">Please Enter Name</div> : null}
            </div>
            {!user
              ? <>
                  <div className='tw-text-sm tw-text-gray-600'>Whatsapp PhoneNumber(Select Country code)</div>
                  <div className="tw-mb-4">
                    <div className='w3-flex w3-flex-vcenter w3-border'>
                      <select className="tw-outline-none w3-padding-8" 
                        onChange={(evt) =>{
                          ctx.country_phone_code = evt.target.value
                          rerender()
                        }}
                        value={ctx.country_phone_code || DEFAULT_COUNTRY_PHONE_CODE}>
                      {PHONE_NUMBER_CODES.map(code => (
                        <option key={code} value={code}>+{code}</option>
                      ))}
                      </select>
                      <input type="number" className='w3-input w3-focus-no-outline w3-border-none w3-white'  placeholder='Enter Phone number here'
                          defaultValue={user?.phone_number || ''}
                          onChange={(evt) => setPhoneNumber(evt.target.value)}                    
                      />
                    </div>
                    {form_submitted && !phone_number ? <div className="tw-text-xs tw-text-red-500 tw-mt-1">Please Enter Phone Number</div> : null}
                  </div>
                </>
              : null
            }
            <div className='tw-text-sm tw-text-gray-600'>Address</div>
            <SelectAddress onChange={setAddressWithTags} />
            <SelectTags 
              tags={area_tags.concat(non_area_tags)}
              selected_tags={selected_tags}
              onSelectedTagsUpdated={(tags) => ctx.selected_tags= tags}
              className="tw-mt-8 tw-mb-4"
              placeholder_text={"Select Areas"}
            />
            <div className='flex-box tw-gap-4 tw-border-t tw-pt-4'>
              <label className='tw-flex tw-items-center '>
                <input type="checkbox" className='w3-check tw-mr-2' 
                    defaultChecked={ctx.do_not_notify}
                    onChange={(evt) => ctx.do_not_notify = evt.target.checked}
                />
                <div className='tw-text-sm tw-text-gray-800'>Do Not Notify</div>
              </label>
              <div className='btn-primary-v2 w3-round-xlarge' onClick={doAddCustomer}>
                  {user ? 'Update Customer' : 'Add Customer'}
              </div>
            </div>
        </div>
    )
}
export default AddNewCustomer;
