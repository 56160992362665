// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--primary-color: #15956e;
	--secondary-color: #052f2f;
	--highlight-color: #ffb93e;
	--main-text-color: #303030;
	--sub-text-color: #626f86;

	/* Buttons colors */
	--primary-btn-hover-color: #0c7c5a;
	--secondary-btn-hover-color: #ecf4f1;
	--tertiary-hover-border-color: #e3e3e3;
}
`, "",{"version":3,"sources":["webpack://./src/css/variables.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,0BAA0B;CAC1B,0BAA0B;CAC1B,0BAA0B;CAC1B,yBAAyB;;CAEzB,mBAAmB;CACnB,kCAAkC;CAClC,oCAAoC;CACpC,sCAAsC;AACvC","sourcesContent":[":root {\n\t--primary-color: #15956e;\n\t--secondary-color: #052f2f;\n\t--highlight-color: #ffb93e;\n\t--main-text-color: #303030;\n\t--sub-text-color: #626f86;\n\n\t/* Buttons colors */\n\t--primary-btn-hover-color: #0c7c5a;\n\t--secondary-btn-hover-color: #ecf4f1;\n\t--tertiary-hover-border-color: #e3e3e3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
