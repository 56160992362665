import { MILLIS_IN_A_DAY } from "base/constants";
import { DateView, formatDateToString } from "base/ui/date";
import { TagsInput } from "base/ui/misc";
import { Popup } from "base/ui/popups";
import { idToTitle, last, len } from "base/utils";
import { sanitizeToId, useRerender } from "base/utils/common";
import React, { useEffect, useState, useRef } from "react";

const DATE_FORMAT_REMAP = {
	"YYYY-MM-DD-HH-mm": "YYYY-MM-DD HH:mm",
}

function SearchTagSuggestionDropDownView({tag, tag_type, selection}){
	return (
		<div className='tw-space-1'>
			<div className='tw-grow'>Tag: <b>{idToTitle(tag)}</b></div>
			{
				tag_type?.startsWith("date")
				?	<div className="">
						<div className='tw-flex tw-gap-1 tw-flex-align-center'>
							<b>from</b>
							<input type='datetime-local'
								className="input-primary"
								onChange={(evt) => {
									// to epoch seconds
									let format = tag_type.substring(5); // "date-"
									format = DATE_FORMAT_REMAP[format] || format; // remap format to store in backend in more readable format
									selection.from = formatDateToString(new Date(evt.target.value), format);
									selection.title = `${idToTitle(tag)} [${selection.from} - ${selection.to || "Any"}]`
								}}
								onClick={(evt) => evt.stopPropagation()}
							/>
						</div>
						<div className='tw-flex tw-gap-1 tw-flex-align-center'>
							<b>to</b>
							<input type='datetime-local'
								className="input-primary"
								onChange={(evt) => {
									// to epoch seconds
									let format = tag_type.substring(5); // "date-"
									format = DATE_FORMAT_REMAP[format] || format;
									selection.to = formatDateToString(new Date(evt.target.value), format);
									selection.title = `${idToTitle(tag)} [${selection.from || "Any"} - ${selection.to}]`
								}}
								onClick={(evt) => evt.stopPropagation()}
							/>
						</div>
					</div>
				:   null
			}
		</div>
	)
}


/**
 * 
 * @param tag_types => {"tag": "date-YYYY-MM-DD", "tag": "date-YYYY-MM-DD-HH-mm"}
 * @returns 
 */
function SearchTagsWithEsValues({tag_types, onTagsChange, onTextSearch, className}){
	const ctx = useRef({}).current;
	const rerender = useRerender();
	
	const onTagsSelectedChange = (selected) => {
		// convert to ES query
		const tags_with_es_values = Object.fromEntries(
			selected.map((tag) => {
				const values = [];
				if(tag.from){values.push("gte:" + tag.from)}
				if(tag.to){values.push("lte:" + tag.to)}
				return [tag.tag, values.join(",")]
			})
		);
		ctx.tags_with_es_values = tags_with_es_values;
		onTagsChange?.(ctx.tags_with_es_values, ctx.tags_operator);
		rerender();
	}

	const doOperatorChange = (evt) => {
		ctx.tags_operator = evt.target.value;
		onTagsChange?.(ctx.tags_with_es_values, ctx.tags_operator);
		rerender();
	}

	return (
		<div className="tw-flex tw-gap-1 tw-flex-grow">
			<TagsInput
				className={`${className || ""} tw-flex-grow`}
				onSelectedChange={onTagsSelectedChange}
				onTextSearch={onTextSearch}
				suggestions={
					async (text) => {
						const suggestions = []; // for text search
						if(text){
							suggestions.push({
								"view": <>{"Search for \""}<b>{text}</b>{"\""}</>,
								"selection": null
							})
						}

						for(const [tag, tag_type] of Object.entries(tag_types)){
							if(tag.toLowerCase().includes(text.toLowerCase())){
								const suggestion_obj = {"selection": {"title": tag, "tag": tag, "tag_type": tag_type}}; // from: 0, to: 0
								suggestion_obj.view = (
									<SearchTagSuggestionDropDownView 
										tag={tag} tag_type={tag_type} 
										selection={suggestion_obj.selection} 
									/>
								);
								suggestions.push(suggestion_obj)
							}
						}
						return suggestions;
					}
				}		
			/>
			{
				len(ctx.tags_with_es_values) >= 2 
				?	<select className="dropdown" 
						defaultValue={"AND"}
						onChange={doOperatorChange}
					>
						<option value="AND">AND</option>
						<option value="OR">OR</option>
					</select>
				:	null
			}
		</div>
	)
}

/**
 * 
 * @param {selected} {tag: value}
 * @returns 
 */
function AddTagsWithValues({selected:_selected, tag_types, onTagsChange}){
	const [selected, setSelected] = useState([]);

	useEffect(
		() => {
			setSelected(Object.entries(_selected || {}).map(([tag, value]) => {
				let title = idToTitle(tag);
				if(value){
					title += ": " + value;
				}
				return {"title": title, "tag": tag, "value": value}
			}));
		}, [_selected]
	);
	return (
		<TagsInput
			placeholder={"Add Tags"}
			selected={selected}
			onSelectedChange={
				(selected) => {
					let tags_with_value = Object.fromEntries(
						selected.map((tag) => {return [tag.tag, tag.value || ""]})
					);
					onTagsChange?.(tags_with_value)
				}
			}
			suggestions={
				async (text) => {
					if(text.length < 3) return;
					let suggestions = [];
					for(let [tag, tag_type] of Object.entries(tag_types)){
						if(tag.startsWith(text)){
							let ret = {"selection": {"title": idToTitle(tag), "tag": tag}};
							ret.view = (
								<div className='tw-flex tw-gap-1 tw-items-center'>
									<div className='tw-grow'>{idToTitle(tag)}</div>
									{
										tag_type?.startsWith("date")
										?	<input type='datetime-local'
												className="tw-border tw-rounded"
												onChange={(evt) => {
													// to epoch seconds
													let format = tag_type.substring(5); // "date-"
													format = DATE_FORMAT_REMAP[format] || format;
													ret.selection.value = formatDateToString(new Date(evt.target.value), format);
													ret.selection.title = `${idToTitle(tag)} [${ret.selection.value}]`
												}}
												onClick={(evt) => evt.stopPropagation()}
											/>
										:	null
									}
								</div>
							);
							suggestions.push(ret)
						}
					}
					return suggestions;
				}
			}
		/>
	)

}
/* THis will render a component to update tag types */
function TagsWithTypesEditor({tag_types, onUpdated}){
	return (
		<TagsInput
			selected={
				Object.entries(tag_types || {}).map(
					([tag, tag_type]) => {
						let title = idToTitle(tag);
						tag_type && (title += `[${tag_type}]`);
						return {
							"title": title,
							"tag": tag,
							"tag_type": tag_type
						}
					}
				)
			}
			onSelectedChange={
				(selected) => {
					let selected_tags_with_types = Object.fromEntries(selected.map((tag) => [tag.tag, tag.tag_type || ""]));
					onUpdated?.(selected_tags_with_types);
				}
			}
			suggestions={
				async (text) => {
					if(text.length < 3) return;
					let ret = {"selection": {"title": text, "tag": sanitizeToId(text)}};
					ret.view = (
						<div className='tw-flex tw-flex-align-center'>
							<div className='tw-grow'>{idToTitle(text)}</div>
							<select defaultValue={""}
								onClick={(evt) => evt.stopPropagation()}
								onChange={(evt) => {
									let title = text;
									let tag_type = evt.target.value
									tag_type && (title+= `[${tag_type}]`);
									ret.selection= {
										"title": title,
										"tag": sanitizeToId(text),
										"tag_type": tag_type
									}
								}}
							>
								<option value="">Generic</option>
								<option value="date-YYYY-MM-DD">Date</option>
								<option value="date-YYYY-MM-DD-HH-mm">Date And Time</option>
								<option value="date-MM-DD">Day and Month</option>								
							</select>
						</div>
					);
					return [ret];
				}
			}
		/>
	);
}

function ShowTagsWithValues({tags, tag_types}){
	return (
		<div className="tw-flex tw-gap-1 tw-items-center">
			{
				Object.entries(tags || {}).map(([tag, value]) => {
					return (
						<div key={tag} className='tw-border tw-rounded-xl tw-p-1 tw-px-2'>
							{tag} {value}
						</div>
					)
				})
			}
		</div>
	)
}

export {TagsWithTypesEditor, AddTagsWithValues, SearchTagsWithEsValues, ShowTagsWithValues};
