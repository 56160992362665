import mixpanel from 'mixpanel-browser';

import { IS_DEV } from 'base/constants';

// Can see mixpanel events in web console
const debug_mode = IS_DEV ? {debug: true} : {}

mixpanel.init('f02d17d50469c0bc576e0c153406bf50', {
  ...debug_mode,
  persistence: "localStorage",
});

const env_check = !IS_DEV; // always keep false only make it true for local testing

const EventTracker = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const FREE_TRIAL_EVENTS = {
  SIGN_UP_INITIATED: "free_trial_signup_initialized",
  SIGN_UP_OTP_REQUESTED: "free_trial_signup_otp_requested", // includes props
  SIGN_UP_SUCCESS: "free_trial_signup_success", // include props
  SHOP_CREATE_INITIATED: "free_trial_shop_create_initiated",
  SHOP_CREATED: "free_trial_shop_created", // includes props
  ADD_PRODUCT: "free_trial_add_product", // includes props
  ADD_TEST_PRODUCT: "free_trial_add_test_product",
  SHOP_VIEW: "free_trial_shop_view", // includes props
  SPEAK_TO_SPECIALIST: "free_trial_speak_to_specialist", // includes props with plan details
  SCHEDULE_A_CALL: "free_trial_call", // includes props
  GO_TO: "free_trial_goto",
  WA_CATALOG_UPGRADE: "free_trial_wa_catalog_upgrade",
  UPDATE_NUMBER_INITIATED: "free_trial_update_number_initiated",
  UPDATE_NUMBER_SUCCESS: "free_trial_update_number_success",
  SIDEBAR:"free_trial_sidebar_cta",
}

export const PAGE_EVENTS = {
  // Customer Page Events
  ADD_CUSTOMER: "add_customer_clicked",
  UPLOAD_CUSTOMER: "upload_customer_clicked",
  // Broadcast Page Events
  CREATE_BROADCAST: "create_broadcast_clicked",
  CREATE_TEMPLATE: "create_template_clicked",
  BROADCAST_SENT: "Broadcast_Sent",
  // Catalog Page Events
  CATALOG_CREATE_NEW_CATALOG_CLICKED: "Catalog_CreateNewCatalog_Clicked",
  // Product Page Events
  PRODUCTS_ADD_NEW_PRODUCT_CLICKED:"Products_AddNewProduct_Clicked",
  // Delivery Route Points Page Events
  DELIVERY_POINT_ADD_NEW_DELIVERYPOINT_CLICKED: "DeliveryPoint_AddNewDeliveryPoint_Clicked",
  // Payment Options Page Events
  ORDER_PAYMENT_MADE: "Order_Payment_Made",
  // Login Page Events
  DASHBOARD_LOGIN_BUTTON_CLICKED: "Dashboard_LoginButton_Clicked",
  // Customer Support Events
  CUSTOMER_SUPPORT_BUY_FOR_CUSTOMER: "CustomerSupport_BuyForCustomer",
  CUSTOMER_SUPPORT_TAG_ADDED: "CustomerSupport_Tag_Added",
  CUSTOMER_SUPPORT_CHAT_RESOLVED: "CustomerSupport_Chat_Resolved",
  // WhatsApp Button Generator Events
  WHATSAPP_BUTTON_GENERATOR_DETAILS: "Whatsapp_Button_Generator_Details",
  WHATSAPP_BUTTON_GENERATOR_CODE_COPIED: "Whatsapp_Button_Generator_Code_Copied",
  // Feed Page Events
  FEED_ORDER_RECEIVED: "Feed_Order_Received",
  FEED_MESSAGE_RECEIVED: "Feed_Message_Received",
  // Settings Page Evemts
  SETTINGS_PAYMENT_GATEWAY_INTEGRATIONS_SAVED: "Settings_Payment_Gateway_Integrations_Saved",
  // Analytics Page Events
  ANALYTICS_DASHBOARD_VIEWED: "Analytics_Dashboard_Viewed",
}

export default EventTracker;