import { faPhoneSquare, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';

import { IS_MOBILE } from 'base/constants';
import EventTracker, { PAGE_EVENTS } from 'base/eventsTracker';
import { useBroadcastedState } from 'base/utils/events';

import OtpLoginForm from './OtpLoginForm';
import IMG_SUCCESS from "../../../images/onboarding/ic_success.svg"
import WhatsAppSvg from '../../../images/whatsapp.svg'
import { cache, useLogin } from '../../../user';
import { SukhibaSideFrame } from '../../ui/commonUI';


const LoginForm = () => {
    const {user, wa_login_link, getWaLoginData, checkUserLoginActivated} = useLogin();

    const [is_qr_mode, setQRMode] = useState(false);
    const [is_page_visible] = useBroadcastedState("is_page_visible", true);

    const delivery_route_id = cache.get("selected_delivery_route_id");

    useEffect(() => {
        let login_poller_timer = null;
        if (is_qr_mode && wa_login_link) {
            if (IS_MOBILE) {
                window.location.href = wa_login_link;
            }
            /* keep polling until the scan popup is open */
            login_poller_timer = setInterval(
                () => {
                is_page_visible && checkUserLoginActivated();
                }, 3000
            );
        } else {
            clearInterval(login_poller_timer)
        }
        return () => clearInterval(login_poller_timer) 
    }, [is_qr_mode, wa_login_link, is_page_visible])

    const qrLogin = () => {
        setQRMode(true);
        if (!wa_login_link) {
            getWaLoginData();
        }
    }

    if (user) {
        EventTracker.identify(user?._id);
        EventTracker.track(PAGE_EVENTS.DASHBOARD_LOGIN_BUTTON_CLICKED)
        return (
            <div className='tw-grid tw-py-16 tw-text-center tw-justify-items-center'>
                <img src={IMG_SUCCESS} alt="success" />
                <div className='font-inter tw-text-lg tw-mt-4'>You have Logged In successfully !!</div>
                {/* {delivery_route_id 
                ?   <div className='tw-px-8'>
                        <button className='tw-bg-yellow-500 tw-px-4 tw-py-2 tw-w-full tw-rounded' onClick={() => navigate(`/${delivery_route_id}`)}>
                            Go to Shop
                        </button>
                    </div> 
                : null
                } */}
            </div>
        )
    }


    return (
        <SukhibaSideFrame>
            <div className="md:tw-p-8 tw-text-black">
                {
                    !is_qr_mode 
                    ?   <OtpLoginForm delivery_route_id={delivery_route_id} />
                    :   !IS_MOBILE 
                        ?  <>
                                <div className="tw-text-lg font-inter tw-my-8 tw-text-center">Scan QR code</div>
                                <div className="flex-box tw-px-4 tw-min-w-[280px]">{wa_login_link ? <QRCode value={wa_login_link} /> : null}</div>
                            </>
                        : <div>Logging In via Whatsapp..</div>
                }
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-4 tw-mb-10 tw-mt-8">
                    <div className="tw-w-2/5 tw-border-t" />
                    <span className="tw-text-xs tw-text-gray-400">OR</span>
                    <div className="tw-w-2/5 tw-border-t" />
                </div>
                <div className="tw-text-center tw-text-primary-dark tw-text-sm tw-font-bold tw-mb-10">
                    {
                    !is_qr_mode 
                    ? (
                        !IS_MOBILE 
                        ? (
                            <button onClick={qrLogin} className="tw-outline-0"
                                type='button'
                            >
                                <FontAwesomeIcon icon={faQrcode} />
                                &nbsp;&nbsp;Login using QR Code
                            </button>
                        ) : <button onClick={qrLogin} className="tw-outline-0 tw-inline-flex tw-items-center"
                                type="button"
                            >
                                <img src={WhatsAppSvg} className="tw-w-6" alt="whatsapp"/>
                                &nbsp;&nbsp;Login using WhatsApp
                            </button>
                        ) 
                    : (
                        <button onClick={() => setQRMode(false)} className="tw-outline-0" type='button'>
                            <FontAwesomeIcon icon={faPhoneSquare} />
                            &nbsp;&nbsp;Login using Phone Number
                        </button>
                    )}
                </div>
            </div>
        </SukhibaSideFrame>
    );
}

export default LoginForm