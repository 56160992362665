import { faCheckCircle, faDownload, faUpload, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import EventTracker, { FREE_TRIAL_EVENTS } from "base/eventsTracker";
import { Popup } from "base/ui/popups";
import { EmptyView, LoadingView } from "base/ui/status";
import { useOnScroll } from "base/ui/utils";
import { len } from "base/utils";
import { loadGoogleMapsScript, useRerender } from "base/utils/common";
import { useBroadcastedState } from "base/utils/events";

import AddNewCustomer from "./AddNewCustomer";
import DeliveryRouteUser from "./DeliveryRouteUser";
import { SearchTagsWithEsValues } from "../../../csm/common";
import UploadSheetView from "../../../csm/components/ui/UploadSheetView";
import BackHeader from "../../layout/BackHeader";
import { NoDataView, UserBadge } from "../../ui/commonUI";
import { GenericException } from "base/ui/errors";


function getTags(delivery_route) {
  return [
    ...new Set(delivery_route?.delivery_points?.map((cp) => cp.area).filter((area) => area)),
    ...new Set(delivery_route?.data?.additional_tags?.map(tag => tag?.trim()).filter((tag) => tag))
  ];
}

function UploadPopUp(delivery_route, fetchUsers) {
  const popup = Popup.show('Upload Users', 
  <UploadSheetView
    props={{
      sync_url: `/api/sync/org/${delivery_route.delivery_org._id}/customers?delivery_route_id=${delivery_route._id}`
    }}
    onCancel={() => {
      popup.close();
    }}
    onSubmit={(data) => {
      EventTracker.track(FREE_TRIAL_EVENTS.UPLOAD_CUSTOMER);
      popup.close()
      fetchUsers && fetchUsers()
    }}
  />)
}

function CustomersList({ is_select_list, selected_users = [], setSelectedUsers }) {
  const [delivery_route] = useBroadcastedState("selected_delivery_route");
  const scrollable_el_ref = useRef();
  const rerender = useRerender();
  const ctx = useRef({}).current;
  const [screen] = useBroadcastedState("tw_screen_size");

  const { delivery_org_id } = useParams();
  
  const setIsLoading = (is_loading) => {
    ctx.is_users_loading = is_loading;
    rerender();
  }

  /* fetches default list of users based on latest updated */
  const fetchUsers = () => {
    if(ctx.is_users_loading || delivery_route.has_more_users === false) return;
    setIsLoading(true);
    axios.post(
      `/api/admin/users/${delivery_route._id}`,
      { "cursor": delivery_route.next_users_cursor }
    ).then((resp) => {
      if (resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
      /* we save it on delivery route users as the delivery route obj is on the top, navigating pages doesn't need to refetch */
      delivery_route.users = delivery_route.users || [];
      resp.data.users && delivery_route.users.push(...resp.data.users);
      delivery_route.next_users_cursor = resp.data.next_users_cursor;
      delivery_route.has_more_users = resp.data.has_more_users;
      rerender();
    }).finally(
      () => {setIsLoading(false);}
    );
  };
  /* initial loading if delivery_route is not preloaded with users */
  useEffect(() => {!delivery_route.users && fetchUsers()}, [delivery_route]);

  /* search based on params */
  const searchUsers = (more) => {
    if (ctx.is_users_loading)  return;
    /* tags */
    const selected_tags = {...ctx.selected_tags};
    if(ctx.credit_given_customers) selected_tags["credit_given"] = ""; // credit_given is a tag
    if (!ctx.search_text && !len(selected_tags)) {
      ctx.search_result_users = undefined;
      rerender();
      return;
    }
    if(more && !ctx.has_more_search_users) return; 
    setIsLoading(true);
    axios.post(
      `/api/admin/users/${delivery_route._id}?action=search`,
      {
        "search_text": ctx.search_text,
        "tags": selected_tags, // credit_given is a tag
        "tags_operator": ctx.tags_operator,
        "cursor": more ? ctx.next_search_users_cursor: undefined
      }
    ).then(
      (resp) => {
        if (resp.data.errors) return;
        ctx.search_result_users = more ? (ctx.search_result_users || []): []; // if more, append to existing else new array
        resp.data.users && ctx.search_result_users.push(...resp.data.users);
        ctx.next_search_users_cursor = resp.data.next_users_cursor;
        ctx.has_more_search_users = resp.data.has_more_users;
        ctx.search_users_count = resp.data.total;
     }
    ).finally(
      () => {setIsLoading(false);}
    )
  };

  useOnScroll(
    scrollable_el_ref.current, 
    (percent) => {percent === 100 && (ctx.search_result_users ? searchUsers(true) : fetchUsers())}, 
    [delivery_route]
  );

  const addCustomer = () => {
    var popup = Popup.show(
      "Add Customer",
      <AddNewCustomer
        delivery_route={delivery_route}
        onSave={
          (user) => {
            EventTracker.track(FREE_TRIAL_EVENTS.ADD_CUSTOMER);
            delivery_route.users = delivery_route.users || [];
            delivery_route.users.unshift(user);
            delivery_route.users_count += 1;
            rerender();
            popup.close();
          }
        }
      />
    );
  }

  const onUserUpdated = (user) => {
    let index = delivery_route.users?.findIndex(_user => _user._id === user._id);
    if (index >= 0) {
      delivery_route.users[index] = user;
    }
    index = ctx.search_result_users?.findIndex(_user => _user._id === user._id);
    if (index >= 0) {
      ctx.search_result_users[index] = user;
    }
    rerender();
  }

  // Loading maps here first to keep it ready for add customer
  useEffect(() => {loadGoogleMapsScript('AIzaSyCSMLgYj7Uxz34Afcvclun1FslKy6ILtwI')}, [])

  return (
    <>
    {
      !is_select_list && !screen.lg  && !delivery_org_id 
      ? <BackHeader title="Customers" /> 
      : null
    }
    <div className={`tw-flex tw-flex-col max-lg:full-height ${delivery_org_id ? "tw-py-2" : "tw-p-3"}`}>
      {!is_select_list
        ? <div className="tw-flex tw-gap-4 tw-flex-wrap tw-justify-between">
          <SearchTagsWithEsValues
            tag_types={{
              "ordered": "date-YYYY-MM-DD-HH-mm",
              "cancelled": "date-YYYY-MM-DD-HH-mm",
              "checkout": "date-YYYY-MM-DD-HH-mm",
              ...Object.fromEntries(getTags(delivery_route).map((tag) => [tag, ""]))
            }}
            onTagsChange={(tags, tags_operator) => {
              ctx.selected_tags = tags;
              ctx.tags_operator = tags_operator;
              searchUsers();
            }}
            onTextSearch={(search_text) => {
              ctx.search_text = search_text;
              searchUsers();
            }}
            className="max-sm:tw-w-full tw-flex-1"
          />
          <div>
            <button type="button" className="btn-secondary" onClick={() => UploadPopUp(delivery_route, fetchUsers)}>
              <FontAwesomeIcon icon={faUpload} />&nbsp; <span className="max-lg:tw-hidden">Upload</span>
            </button>
            <a href={`/download/users/?delivery_route_id=${delivery_route._id}`} target="_blank" rel="noreferrer"
              className="btn-secondary tw-mx-2">
              <FontAwesomeIcon icon={faDownload} />&nbsp; <span className="max-lg:tw-hidden">Download</span>
            </a>
            <button type="button" className='btn-primary-v2 tw-mr-2'
              onClick={addCustomer}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              <span className='tw-text-xs'>&nbsp; Add</span>
            </button>
          </div>
        </div>
        : null
      }
      <div className={`tw-relative tw-border-b ${is_select_list ? '' : ' tw-pt-2 tw-pb-4'}`}>
        {!is_select_list
          ? <div className="tw-flex tw-items-center tw-gap-4 tw-mt-2 tw-flex-wrap">
              <button type="button" className={`tw-border ${!ctx.credit_given_customers ? 'tw-border-primary-v2 tw-bg-primary-v2 tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
                onClick={() => {
                  if (ctx.credit_given_customers) {
                    ctx.credit_given_customers = false;
                    searchUsers();
                  }
                }}
              >
                All Customers
              </button>
              <button type="button" className={`tw-border ${ctx.credit_given_customers ? 'tw-border-primary-v2 tw-bg-primary-v2 tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
                onClick={() => {
                  ctx.credit_given_customers = true;
                  searchUsers();
                }}
              >
                Customers with Credit
              </button>
              <div className="tw-ml-auto tw-mr-0">
                <span className='tw-rounded tw-text-sm tw-px-2 tw-py-[2px] tw-text-secondary-text'>
                  {`Total Count: ${ctx.search_result_users ? ctx.search_users_count : delivery_route.users_count}`}
                </span>
              </div>
            </div>
          : null
        }
      </div>
      <div className={`tw-py-2 tw-overflow-auto tw-divide-y tw-grow tw-relative lg:customers-maxh ${is_select_list ? '' : 'max-lg:tw-pb-10'}`}
        ref={scrollable_el_ref}
      >
          {
            ctx.is_users_loading
            ? <div className="tw-text-black tw-text-center tw-bg-gray-100 tw-sticky tw-mb-2">Searching..</div>
            : null
          }
          {
            ctx.search_result_users && !ctx.search_result_users.length
            ? <NoDataView title="No Results to show"/>
            : null
          }
          {
            delivery_route.users && !delivery_route.users.length
            ? <NoDataView title="No Users Yet" />
            : null
          }
          {
            (ctx.search_result_users || delivery_route.users)?.map(
              (user) => {
                const is_user_selected = is_select_list && selected_users?.includes(user._id);
                return is_select_list
                  ? <button type="button" className="hflex" key={user._id} 
                      onClick={() => { setSelectedUsers(is_user_selected ? selected_users.filter(_id => _id !== user._id) : [...selected_users, user._id]) }}
                    >
                      <UserBadge user={user} />
                      {
                        !is_user_selected
                        ? null
                        : <FontAwesomeIcon icon={faCheckCircle} className='tw-text-green-600' />
                      }
                    </button>
                  : <DeliveryRouteUser
                      user={user} delivery_route={delivery_route}
                      onUserUpdated={onUserUpdated}
                      key={user._id}
                    />
              }
            )
          }
        </div>
      </div>
    </>
  );
}
export default CustomersList;