import { useEffect, useState } from 'react';
import axios from 'axios';
import { len } from 'base/utils';
import { GenericException } from 'base/ui/errors';
import { broadcaster, useBroadcastedState } from 'base/utils/events';
import { useRerender } from 'base/utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';


/* this file contains third party widgets and integrations */

function ShopifyLineItem({product, onSelect, onRemove}){
	return <div className='w3-card-2 w3-margin-right' style={{"minWidth": "200px"}}>
		<div className='w3-row tw-relative' onClick={onSelect && (() => onSelect(product))}>
			{
				onRemove
				? 	<div className='tw-absolute tw-right-0 tw-top-0 tw-px-1'
						onClick={() => onRemove(product)} 
						style={{"cursor": "pointer"}}
					>X</div>
				: null
			}
			<div className='w3-col s4'>
				<img src={product.image} style={{"height": "64px"}} alt={product.title}/>
			</div>
			<div className='w3-padding-left w3-col s8'>
				<div>{product.title}</div>
				<div>{product.price}</div>
			</div>
		</div>
	</div>;
}


function ShopifySendCart({ session_data, onCartSent }){
	const [org] = useBroadcastedState("org");
	/* 
		- input (debounce /shopify/actions/{org._id}?action=search_products - returns line items)
		- Search Results  ( should contain product image, name, price cards horizontally laid out)
		- selected search results (should contain product image, name, price, quantity, discount, total price)
		- discount percentage (input numbre)
		- buttons -> send cart (/shopify/actions/{org._id}?action=send_checkout_link)
	*/
	const [search_results_products, setSearchResultsLineItems] = useState([]);
	const [selected_products, setSelectedLineItems] = useState([]);
	const [search_text, setSearchText] = useState("");
	const [discount_percentage, setDiscountPercentage] = useState(0);
	const [errors, setErrors] = useState(null);
	const rerender = useRerender();
	useEffect(
		() => {
			if(!search_text) return;
			const timer = setTimeout(() => {
				axios.post(
					`/api/shopify/actions/${org._id}?action=search_products`,
					{"search_text": search_text}
				)
					.then((resp) => setSearchResultsLineItems(resp.data.products));
			}, 500);
			return () => clearTimeout(timer);
		}, [search_text]
	);	

	const doSendCart = () => {
		axios.post(`/api/shopify/actions/${org._id}?action=send_checkout_link`, {
			"products_with_quantities": selected_products,
			"discount_percentage": discount_percentage,
			"session_id": session_data.session._id,
		}).then((resp) => {
			if(resp.data.errors){
				setErrors(resp.data.errors);
				return;
			}
			resp.data.im_list?.map((im) => broadcaster.broadcast_event("chat:message_sent", im, session_data));
			onCartSent && onCartSent();
		});
	};

	const onProductSelect = (product) => {
		const existing_product = selected_products.find((li) => li.item_id === product.item_id);
		if(existing_product){
			existing_product.quantity += 1;
			rerender();
			return;
		}
		setSelectedLineItems([
			...selected_products, 
			{
			...product,
			"quantity": 1,
			}
		]);
	};

	return <div className='w3-padding'>
		{errors && <GenericException ex={errors} />}
		<input type='text' className='input-primary tw-mb-2' placeholder='Search Products'
			value={search_text} onChange={(e) => setSearchText(e.target.value)}
		/>
		{
			len(search_results_products) && len(search_text) > 1
			? <div className='tw-flex tw-flex-row tw-gap-x-2 tw-py-4 tw-overflow-x-auto'>
				{
					search_results_products.map((product) => 
						<ShopifyLineItem product={product} onSelect={onProductSelect}
							key={product.item_id}
						/>
					)
				}
				</div>
			: null
		}
		{
			len(selected_products)
			? 	<div>
					<h4>Selected Items</h4>
					<div className='tw-flex tw-flex-row tw-gap-x-2 tw-py-4 tw-overflow-x-auto'>
						<table className='w3-table-all'>
							<thead>
								<tr>
									<th>Product</th>
									<th>Quantity</th>
								</tr>
							</thead>
							{
								selected_products.map((product) => 
									<tr key={product.item_id}>
										<td>{product.title}</td>
										<td>
											<div className='tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
												<FontAwesomeIcon icon={faMinusSquare} onClick={() => {
													product.quantity -= 1;
													if(product.quantity <= 0){
														setSelectedLineItems(selected_products.filter((li) => li.item_id !== product.item_id));
														return;
													}
													rerender();
												}} />
												{product.quantity}
												<FontAwesomeIcon icon={faPlusSquare} onClick={() => {
													product.quantity = product.quantity + 1
													rerender();
												}} />
											</div>
										</td>
									</tr>
								)
							}
						</table>
					</div>
				</div>
			: null
		}
		<div className='tw-mb-2'>
			<h4>Give Extra Discount %</h4>
			<input type='number' className='input-primary tw-mb-2' placeholder='Discount Percentage' 
				onChange={(e) => setDiscountPercentage(e.target.value)}
				value={discount_percentage}
			/>
		</div>
		<button className='btn-primary' type="button" onClick={doSendCart}>Send Checkout Link</button>
	</div>

}

export { ShopifySendCart };