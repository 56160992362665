import { faChevronDown, faChevronRight, faSearch, faTruckFast, faUser, faUsers, faX, faHistory, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CountDownView, DateView, TimeRangeView } from 'base/ui/date';
import { GenericException } from 'base/ui/errors';
import { Popup } from 'base/ui/popups';
import { broadcaster, useBroadcastedState } from 'base/utils/events';
import Fuse from 'fuse.js'
import { EmptyView, LoadingView, LoadingOverlay, NotFoundView } from 'base/ui/status';

// assets
import ClockIcon from "./images/clock.svg"
import MapMarker from "./images/map_marker.svg"
import FilterIcon from "./images/filter.svg"

// css
import { getByIds } from 'base/get_by_ids';
import { currencyToSymbol, openWhatsapp, useRerender } from 'base/utils/common';
import { cache } from './user';

import { Constants, IS_MOBILE, ViewPort } from 'base/constants';

import { useLocalStorageState } from 'base/utils/events';
import { SuggestedField } from 'base/ui/suggested_field';
import { useOnScroll } from 'base/ui/utils';
import ResponsivePopup from './components/ui/ResponsivePopup';
import {formCategoryTree, getCategoriesForSearch, getParentCategories, getSelectedCategory} from './helpers/CategoriesHelper';
import { getCurrentUser } from 'base/app';
import ReactSwipe from 'react-swipe';
import { ChatSession } from 'base/ui/chat';
import { PerformanceMonitor } from './performanceMonitor';
import SelectDeliveryTime from './components/features/store/SelectDeliveryTime';
import SelectDeliveryPoint from './components/features/store/SelectDeliveryPoint';
import { getCartTotal, getDisplayPrice, isConfigEnable, canModifyOrder } from './helpers/StoreHelper';
import { CategoriesPopup, CategoryBar, CategoryChipsBar } from './components/features/store/Categories';
import { ORDER_STATUS_DELIVERED, ORG_SETTINGS } from './constants';
import ProductDetails from './components/features/store/product/ProductDetails';
import CatalogProduct from './components/features/store/product/CatalogProduct';
import CartAndOrderView from './components/features/store/CartAndOrderView';
import OrderSuccess from './components/features/store/OrderSuccess';
import useCheckout from './components/features/store/useCheckout';
import NoSlotsShortView from './components/features/store/NoSlotsShortView';
import WAOrderPopUp from './components/features/store/WAOrderPopUp';
import UserOrders from './components/features/store/UserOrders';
import SelectBuyForUser from './components/features/store/SelectBuyForUser';
import AddNewCustomer from './components/features/customers/AddNewCustomer';

function App(){
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [delivery_route_id, setDeliveryRouteId] = useState(
        params.delivery_route_id 
        || window.init_data?.delivery_route_id
        || cache.get("selected_delivery_route_id")
    );

    const rerender = useRerender();
        
    /* user selections */
    const [selected_delivery_point, setSelectedDeliveryPoint] 
        = useBroadcastedState("delivery_point_updated");
    /* area and area routes */
    const [area, setArea] = useLocalStorageState('area', searchParams.get('area'));
    /* auth first slot after current time */
    const [selected_delivery_time_range, setSelectedDeliveryTimeRange] = useBroadcastedState("delivery_time_range_updated");

    /* fetched from backend and immutable */
    const [init_data, setInitData] = useState({
        delivery_route: null,
        delivery_time_ranges: [],
        banners: [],
        all_orders: [],
        last_purchased_users: null,
        is_user_manager: false,
        use_backend_search: false,
        categories_tree: []
    });
    const {delivery_route, delivery_time_ranges, banners, all_orders, 
        last_purchased_users, is_user_manager, use_backend_search, categories_tree
    } = init_data;

    /* setCatalogProducts is used as wrapper to _setCatalogBuckets inorder to group and set catalog products and variants. DONOT use _setCatalogBuckets directly  */
    const [catalog_products, _setCatalogBuckets] = useState([]);

    const [order, setOrder] = useBroadcastedState("order_updated");
    const [cart, setCart] = useBroadcastedState("cart_updated", {});

    /* search and loading */
    const [search_text, setSearchText] = useState("");
    const search_bar_ref = useRef();
    const [stick_search_bar, setStickSearchBar] = useState(0);
    const [is_loading, setIsLoading] = useState(false);
    /* inital data ref */
    const ctx = useRef({}).current;
    /* category selection */
    const [selected_category, setSelectedCategory] = useState(null);
    const [show_all_categories, setShowAllCategories] = useState(null);

    /* user */
    const [user, setUser] = useBroadcastedState("user_updated");
    const [buying_for_user, setBuyingForUser] = useState(null);

    /* recommended products */
    const [recommended_products, setRecommendedProducts] = useState(null);
    const [show_recommendations, setShowRecommendations] = useState(false);
    const [is_recomm_loading, setRecommLoading] = useState(false);

    const [screen] = useBroadcastedState('tw_screen_size')
    const navigate = useNavigate();
    const products_ref = useRef(null)
    /* Cart and product View */
    const [review_cart, setReviewCart] = useState(null);
    const [show_details_cp, setShowDetailsCp] = useState(searchParams.get('cp') ? {_id: searchParams.get('cp')} : null);
    const now_millis = new Date().getTime();

    /* Checkout Hook */
    useCheckout({cart, delivery_route, buying_for_user, onCheckoutComplete: () => setReviewCart(null)});

    function handleBannerAction(banner) {
        banner.action_value && window.open(banner.action_value, "_self")
    }

    const checkUserandNavigate = async () => {
        const user = await getCurrentUser();
        if (user && (user.roles?.superadmin || user.roles?.manager || user.roles?.delivery_org_manager)) {
            navigate('/admin', {replace: true})
        } else {
            navigate('/landing', {replace: true})
        }
    }

    useEffect(() => {
        if (!delivery_route_id) {
            checkUserandNavigate()
            return;
        }
        document.body.scrollTop = 0;
    }, [delivery_route_id])

    useOnScroll(
        document.body,
        () => {
            if (search_bar_ref.current) {
                if (!search_bar_ref.current?.offset_top || search_bar_ref.current?.offset_top <= 0) {
                    const {height, top} = search_bar_ref.current?.getBoundingClientRect() || {height: 0, top: 0};
                    search_bar_ref.current.offset_top = top;
                    search_bar_ref.current.client_height = height;
                }
                if (search_bar_ref.current.offset_top > 0) {
                    if(document.body.scrollTop > search_bar_ref.current.offset_top){
                        setStickSearchBar(search_bar_ref.current.client_height);
                    } else {
                        setStickSearchBar(0);
                    }
                }
            }
        },
        []
    )

    useOnScroll(
        document.body,
        (percent) => {
            if(percent < 95) return; // scrolled to top
            if(
                !ctx.server_data 
                || !ctx.next_products_cursor
                || !ctx.has_more_products
                || ctx.is_loading_next_results
            ){
                return;
            }
            loadMoreProducts()
        },
        [delivery_route_id, catalog_products, selected_delivery_time_range?.[0], selected_category, search_text?.length]
    );

    const loadMoreProducts = () => {
        ctx.is_loading_next_results = true;
        rerender();
        axios.post(
            `/api/products/search/${delivery_route_id}`,
            {
                "cursor": ctx.next_products_cursor,
                "delivery_time": selected_delivery_time_range?.[0] || null,
                "search_text": search_text,
                "categories": search_text ? null : getCategoriesForSearch(selected_category),
            }
        ).then((resp) => {
            ctx.next_products_cursor = resp.data.next_products_cursor;
            ctx.has_more_products = resp.data.has_more_products;
            setCatalogProducts([...catalog_products, ...resp.data.catalog_products]);
        }).finally(() => {
            ctx.is_loading_next_results = false;
            rerender();
        })
    }

    const changeDeliveryRoute = (d_id) => {
        setDeliveryRouteId(d_id);
        ctx.server_data = null; // reset so next init request will be sent with cached params
    }
    
    const changeBuyingForUser = (_u) => {
        _u?._id  
            ? searchParams.set("buying_for_user_id", _u._id) 
            : searchParams.delete("buying_for_user_id");
        setSearchParams(searchParams);
        setBuyingForUser(_u);
        setRecommendedProducts(null);
        ctx.server_data = null; // reset so next init request will be sent with cached params
    }

    /* cache deliveyr point changes */
    useEffect(
        () => {
            if(!ctx.server_data) return;  // only cache after initial load
            selected_delivery_point?.area && setArea(selected_delivery_point.area);
            cache.set([delivery_route_id, buying_for_user?._id, "dpoint_name"], selected_delivery_point?.name);
        }, [delivery_route_id, selected_delivery_point?.name, buying_for_user?._id]
    );

    useEffect(() => {
        if (IS_MOBILE && delivery_route && isConfigEnable(delivery_route.other_config, ORG_SETTINGS["WA_REDIRECTION_POPUP"], true)) {
            const wa_order_popup = localStorage.getItem('wa_order_popup');
            const today = new Date().toJSON().slice(0, 10);
            wa_order_popup !== today && showWAOrderPopUp(delivery_route)
            localStorage.setItem("wa_order_popup", today)
        }
    }, [delivery_route]);

    const fetchInit = () => {
        if(!delivery_route_id || is_loading) return; // nothing to do
    
        setIsLoading(true);

        const params = {};
        const cache_cart_id = cache.get([delivery_route_id, buying_for_user?._id, "cart_id"])
        params["delivery_time"] = selected_delivery_time_range?.[0]; // will be automatically used for date
        params["cart_id"] = cart?._id && cart._id === cache_cart_id ? cart._id : null; // Pass cart_id if current cart is of same customer/user
        params["buying_for_user_id"] = buying_for_user?._id
        params["delivery_point_name"] = selected_delivery_point?.name;
        !selected_delivery_point?.area && area && (params["area"] = area);

        // INITIAL LOADING
        if(!ctx.server_data){
            const buying_for_user_id = searchParams.get("buying_for_user_id");
            params["buying_for_user_id"] = buying_for_user_id;
            params["delivery_point_name"] = cache.get([delivery_route_id, buying_for_user_id, "dpoint_name"]);
            params["cart_id"] = searchParams.get('cart_id') || cache.get([delivery_route_id, buying_for_user_id, "cart_id"]);
            params["area"] = area;
        }
        axios.get(
            `/api/init/${delivery_route_id}`,
            {"params": params}
        ).then(
            (resp) => {
                if(resp.data.errors){
                    if(resp.data.errors.invalid_route){
                        setDeliveryRouteId(null);
                    }
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                    return;
                }
                var _delivery_route = resp.data.delivery_route;
                
                /* you are trying to access a different org from a custom domin, redirect to home */
                if(
                    !window.location.hostname.endsWith("sukhiba.com")
                    && window?.delivery_org_id
                    && _delivery_route.delivery_org?._id !== window.delivery_org_id
                ){
                    navigate("/landing", {replace: true});
                    return;
                }

                if (_delivery_route) {
                    cache.set("selected_delivery_route_id", delivery_route_id);
                    broadcaster.broadcast_event("delivery_route_id", delivery_route_id);
                    ctx.server_data = ctx.last_server_data = resp.data;

                    /* fancy stuff */
                    _delivery_route.currency_symbol = currencyToSymbol(_delivery_route.currency)

                    /* init states */
                    const init_data = {
                        delivery_route: _delivery_route,
                        delivery_time_ranges: resp.data.delivery_time_ranges,
                        banners: resp.data.banners,
                        is_user_manager: resp.data.is_user_manager,
                        use_backend_search: resp.data.use_backend_search,
                    };
                    setCatalogProducts(resp.data.catalog_products || []);
                    /* check and set preselections */

                    /* DeliveryArea Routes */
                    let delivery_area_routes = ctx.server_data?.delivery_area_routes;
                    if(
                        delivery_area_routes
                        && !delivery_area_routes.find(d_info => d_info._id === delivery_route_id)
                    ){
                        delivery_area_routes.unshift(ctx.server_data.delivery_route);
                    }            

                    /* set cached data on this delivery route */
                    let selected_delivery_point = _delivery_route.delivery_points.find(
                    (dpoint) => {
                        return dpoint.name === params["delivery_point_name"];
                    }
                    ) || null;

                    setSelectedDeliveryPoint(selected_delivery_point);
                    setSelectedDeliveryTimeRange(resp.data.delivery_time_ranges?.find(x => x[0] === resp.data.delivery_time));
                    ctx.server_data.selected_delivery_point = selected_delivery_point;

                    /* order for this slot */
                    const _order = canModifyOrder(resp.data.order) ? resp.data.order : {};
                    setOrder(_order);
                    /* other orders */
                    resp.data.orders = resp.data.orders || [];
                    if(resp.data.order) resp.data.orders.unshift(resp.data.order);
                    init_data.all_orders = resp.data.orders;
                    /* cart */
                    const _cart = resp.data.cart || {};
                    setCart(_cart);
                    if (review_cart || searchParams.has("cart_id")) {
                        setReviewCart({cart: _cart, order: _order});
                    }
                    searchParams.delete('cart_id');
                    
                    /* mark it on delivery route for later use*/
                    _delivery_route.is_user_manager = resp.data.is_user_manager;
                    
                    setBuyingForUser(resp.data.buying_for_user);
                    if (resp.data.buying_for_user) {
                        searchParams.set('buying_for_user_id', resp.data.buying_for_user?._id);
                    } else {
                        searchParams.delete('buying_for_user_id');
                    }
                    setSearchParams(searchParams);
                    ctx.has_more_products = resp.data.has_more_products;
                    ctx.next_products_cursor = resp.data.next_products_cursor;
                    /* set only when returned by backend */
                    resp.data.user !== undefined && setUser(resp.data.user);

                    if(_order?._id && params["cart_id"] && !resp.data.cart?._id) {
                        /* You send a cart id, but didn't get in response, either the cart was finalized or deleted ? */
                        Popup.slideUp(<OrderSuccess order={resp.data.order} />, {remove_others: true});
                    }

                    /* change website title */
                    broadcaster.broadcast_event('delivery_org_id', _delivery_route.delivery_org?._id);
                    broadcaster.broadcast_event("shop_info", {"title": _delivery_route.delivery_org?.title});
                    
                    /* add categories */
                    resp.data.categories = resp.data.categories || [];
                    if(!resp.data.categories?.length) {
                        var _categories = new Set();
                        resp.data.catalog_products?.forEach(
                            (cp) => {
                                cp.categories?.forEach(_categories.add, _categories);
                            }
                        )
                        resp.data.categories = [..._categories];
                    }
                    init_data.categories_tree = formCategoryTree(resp.data.categories)

                    let categories_names = searchParams.get('categories');
                    categories_names = categories_names?.split(',');
                    if (categories_names?.length) {
                        setSelectedCategory(getSelectedCategory(categories_names, init_data.categories_tree));
                    }

                    /* last ordered users */
                    var last_purchased_user_ids = new Set();
                    resp.data.catalog_products?.forEach(
                    (cp) => {
                        // adding variant info to search keywords
                        if (cp.variant_info) {
                            let variant_keywords = ""
                            Object.keys(cp.variant_info).map(key => {
                                variant_keywords += cp.variant_info[key] + ",";
                            })
                            cp["variant_keywords"] = variant_keywords
                        }

                        cp.last_purchased_user_ids?.forEach(
                            (u_id) => last_purchased_user_ids.add(u_id), last_purchased_user_ids
                        );
                    }
                    );
                    /* index products for search */
                    App.products_index = new Fuse(
                        resp.data.catalog_products,
                        { "keys": ["title", "description", "keywords", "categories", "sku", "variant_keywords"], includeScore: true }
                    );
                    /* index products for category selection */
                    App.products_category_index = new Fuse(
                        resp.data.catalog_products,
                        { "keys": ["categories"], threshold: 0, isCaseSensitive: false, ignoreLocation: true }
                    );
                    
                    /* remove current user */
                    resp.data.user && last_purchased_user_ids.delete(resp.data.user._id);
                    last_purchased_user_ids = [...last_purchased_user_ids];
                    getByIds({"user_ids": last_purchased_user_ids}).then(
                        (data) => {
                            init_data.last_purchased_users = last_purchased_user_ids.map((u_id) => data.users[u_id]);
                        }
                    );
                    if(_delivery_route.custom_css){
                        if(!ctx.custom_style){
                            ctx.custom_style = document.createElement('style');
                            document.head.appendChild(ctx.custom_style)
                        }
                        ctx.custom_style.innerHTML = _delivery_route.custom_css;
                    }
                    setInitData(init_data)
                }
            }
        ).finally(
            () => {
                setIsLoading(false);
            }
        );
    }
    
    /* INIT */
    useEffect(() => {fetchInit()}, [delivery_route_id]);
    useEffect(() => {ctx.last_server_data && ctx.last_server_data.buying_for_user?._id != buying_for_user?._id && fetchInit()}, [buying_for_user?._id]);
    useEffect(() => {ctx.server_data && user?._id && ctx.server_data.user?._id != user._id && fetchInit()}, [user?._id]);
    useEffect(() => {ctx.server_data && selected_delivery_point?.name && ctx.server_data.selected_delivery_point?.name != selected_delivery_point.name && fetchInit()}, [selected_delivery_point?.name]);
    useEffect(() => {ctx.server_data && selected_delivery_time_range?.[0] && ctx.server_data.delivery_time != selected_delivery_time_range[0] && fetchInit()}, [selected_delivery_time_range?.[0]]);
    
    const filterProducts = () => {
        let searched_products;
        if (search_text) {
            searched_products = App.products_index.search(search_text);
        } else {
            const search_categories = getCategoriesForSearch(selected_category);
            searched_products = App.products_category_index.search({$and: search_categories.map(cat => ({categories: cat}))});
        }
        return searched_products.map((res) => res.item);
    }

    /* search Input changes */
    useEffect(
        () => {
          const timeout = setTimeout(
            () => {
                if(search_text.length < 2 && !selected_category){
                    /* nothing to filter */
                    setCatalogProducts(ctx.server_data?.catalog_products || []);
                    return;
                }
                if (use_backend_search) {
                    ctx.has_more_products = false;  //reset on change
                    ctx.next_products_cursor = null; // reset on change 
    
                    setIsLoading(true);
                    axios.post(`/api/products/search/${delivery_route_id}`, {
                        "search_text": search_text, 
                        "categories": search_text ? null : getCategoriesForSearch(selected_category)
                    }).then((res) => {
                        if (res.data) {
                            setCatalogProducts(res.data.catalog_products || []);
                            ctx.has_more_products = res.data.has_more_products;
                            ctx.next_products_cursor = res.data.next_products_cursor;
                        } else {
                            setCatalogProducts(filterProducts());
                        }
                    })
                    .catch(() => setCatalogProducts(filterProducts()))
                    .finally(() => setIsLoading(false))
                } else {
                    setCatalogProducts(filterProducts());
                }
            }, 300
          );
          return () => clearTimeout(timeout);
        },
        //dependencies
        [delivery_route_id, search_text, selected_category, use_backend_search]
    );

    /* search changes */
    useEffect(
        () => {
            if(!catalog_products || !products_ref.current) return;
            document.body.scrollTop = Math.min(document.body.scrollTop, (products_ref.current.offsetTop - 75));
        }, [search_text.length, selected_category]
    );

    /* categories from link */
    useEffect(() => {
        if (categories_tree?.length) {
            if (!selected_category) {
                searchParams.delete('categories');
            } else {
                searchParams.set('categories', getCategoriesForSearch(selected_category)?.join(','))
            }
            setSearchParams(searchParams);
        }
    }, [selected_category])

    /* product from link */
    useEffect(() => {
        if (!show_details_cp) {
            searchParams.delete('cp');
        } else {
            searchParams.set('cp', show_details_cp?._id)
        }
        setSearchParams(searchParams);
    }, [show_details_cp])

    useEffect(() => {
        const cp_id = searchParams.get('cp');
        if ((cp_id || show_details_cp?._id) && cp_id !== show_details_cp?._id) {
            setShowDetailsCp(cp_id ? {_id: cp_id} : null);
        }
    }, [searchParams])

    const setCatalogProducts = (catalog_products) => {
        const non_grouping_variants 
            = (delivery_route?.other_config?.non_grouping_variants || "")
                .split(",")
                .map((s) => s.trim().toLowerCase()) 
            //TODO: use from settings ["color", "colour"]; 
        const _catalog_products = [];
        const _buckets = {};

        catalog_products.forEach((cp) =>{
            // PRODUCT_ID_GREEN_1_2_3
            let key = non_grouping_variants.reduce(
                (s, v) => cp.variant_info?.[v] ? `${s}_${cp.variant_info[v]}` : s,
                cp.variant_group_id || cp._id
            );
            let bucket_cp = _buckets[key];
            if(!bucket_cp){
                _buckets[key] = [];
                /* if any of cp variants present in cart, push all of them to the bucket otherwise push only top cp  */
                let cp_variants_in_cart;
                if (cart?.items && cp.variant_group_id) {
                    cp_variants_in_cart = Object.values(cart.items).filter(cart_item => (
                        cart_item.diff_qty > 0
                        && cart_item.catalog_product?.variant_group_id === cp.variant_group_id
                        && non_grouping_variants.every(v => cart_item.catalog_product.variant_info?.[v] == cp.variant_info?.[v])
                    ))
                }
                if (cp_variants_in_cart?.length > 0) {
                    cp_variants_in_cart.forEach(cart_cp => {
                        _buckets[key].push(cart_cp.catalog_product);
                        _catalog_products.push(cart_cp.catalog_product);
                    })
                } else {
                    _buckets[key].push(cp);
                    _catalog_products.push(cp);
                }
            } else {
                /* set some additional info on the top bucket cp to indicate the contents */
                if(cp.has_discounts) {
                    _buckets[key].forEach(_cp => _cp.has_discounts = true);
                }
            }
        });
        _setCatalogBuckets(_catalog_products); // remove duplicates
        if (_catalog_products.length < 6 && ctx.has_more_products) {
            loadMoreProducts()
        }
    }

    /* if any variants added to cart, add them to catalog product buckets */
    useEffect(
        () => {
            if (cart?.items && catalog_products) {
                setCatalogProducts(catalog_products)
            }
        }, [cart]
    );

    /* click handlers */
    const showDeliveryPointsSelectionPopup = () => {
        var popup = Popup.slideUp(
            <SelectDeliveryPoint delivery_route={delivery_route}
                selected_delivery_point={selected_delivery_point}
                onDeliveryPointSelected={
                    (dpoint) => {
                        if(dpoint){
                            setSelectedDeliveryPoint(dpoint);
                            setSelectedDeliveryTimeRange(null);
                        }
                        popup.close();
                    }
                }
            />,
            {"title": "Select Delivery Location"}
        );
    }

    const showDeliveryTimesSelectionPopup = () => {
        var popup = Popup.slideUp(
            <SelectDeliveryTime
                delivery_route={delivery_route}
                delivery_time_ranges={delivery_time_ranges}
                selected_delivery_time_range={selected_delivery_time_range}
                selected_delivery_point={selected_delivery_point}
                onDeliveryTimeRangeSelected={
                    (delivery_time_range) => {
                        setSelectedDeliveryTimeRange(delivery_time_range);
                        popup.close()
                    }
                }
            />,
            {"title": "Select Delivery Slot"}
        );
    }

    const addCustomerForBFC = () => {
        var popup = Popup.show(
          "Add New Customer",
          <AddNewCustomer
            delivery_route={delivery_route}
            onSave={
                (_user) => {
                    changeBuyingForUser(_user);
                    if (_user) popup.close();
                }
            }
          />
        );
    }

    const showCustomerSelectPopup = () => {
        var popup = Popup.slideUp(
            <SelectBuyForUser
                delivery_route_id={delivery_route._id}
                buying_for_user={buying_for_user}
                onBuyForUserSelect={
                    (_user) => {
                        changeBuyingForUser(_user);
                        if (_user) {
                            popup.close();
                        }
                    }
                }
                onAddCustomer={() => {
                    addCustomerForBFC();
                    popup.close();
                }}
            />,
            {"title": "Select Customer"}
        );
    }

    
    const viewAllAreaRoutes = () => navigate(`/area`);

    const openNotes = (e) => {
        e.stopPropagation && e.stopPropagation();
        ChatSession.open(`customer_notes_${delivery_route_id}_${buying_for_user._id}`);
    }

    const showRecommendations = (e) => {
        e.stopPropagation && e.stopPropagation();
        setShowRecommendations(true);
        if (recommended_products?.length || is_recomm_loading) return;
        setRecommLoading(true);
        axios.get(`/api/bfc_recommendations/${delivery_route_id}?buying_for_user_id=${buying_for_user?._id}`)
        .then((resp) => {
            setRecommendedProducts(resp.data?.catalog_products);
        })
        .finally(() => setRecommLoading(false));
    }

    const addAllRecommendedToCart = async () => {
        const ui_block = Popup.blockUi(
            <span className='w3-center w3-bold'>Adding to Cart..</span>
        );
        const product_id_qty = recommended_products.reduce((result, cp) => {
            result[cp._id] = 1;
            return result;
        }, {});
        axios.post(`/api/bfc_recommendations/${delivery_route_id}?buying_for_user_id=${buying_for_user?._id}&action=add_recommendations_to_cart`,
            {
                product_id_qty, 
                cart_id: cart?._id
            }
        )
        .then((resp) => {
            const _cart = {...cart, ...resp.data.cart};
            broadcaster.broadcast_event("cart_updated", _cart);
            setReviewCart({cart: _cart, order});
            setShowRecommendations(false);
        })
        .finally(() => ui_block.remove());
    }

    

    if (delivery_route) {
        // some context variables to be used accross for the same delivery_route object
        delivery_route.selected_delivery_time = selected_delivery_time_range?.[0];
        delivery_route.selected_delivery_point_name = selected_delivery_point?.name;
        delivery_route.delivery_time_ranges = delivery_time_ranges;
    }
    const has_other_routes = ctx.server_data?.delivery_area_routes?.length > 1;
    const minimum_for_home_delivery = selected_delivery_point?.minimum_for_home_delivery
        || delivery_route?.data?.minimum_for_home_delivery;
    const selected_category_tree = getParentCategories(selected_category);

    var ordering_closes_within = null;
    if(selected_delivery_time_range?.[1] && selected_delivery_point?.processing_time){
        ordering_closes_within = (selected_delivery_time_range[1] - selected_delivery_point.processing_time - now_millis);
    }

    return (
        <PerformanceMonitor id="app_load" interactive={!!delivery_route?._id} send_to_analytics={true}>
            {!delivery_route 
            ?   <LoadingView title={"Loading.."} height={ViewPort.HEIGHT}/>
            :   <>
                    {is_loading ? <LoadingOverlay title={'Loading...'} height={ViewPort.HEIGHT} /> : null}
                    <div className='w3-content'>
                        <div className='tw-bg-primary-bg tw-pb-4'>
                            {/* Delivery Route Selection */}
                            {
                                has_other_routes
                                ?   <div className='tw-text-secondary tw-text-xxs tw-font-bold tw-tracking-wide tw-ml-auto tw-p-2 tw--m-2'
                                        onClick={viewAllAreaRoutes}
                                    >CHANGE VAN</div>
                                :   null
                            }
                            {/* Delivery Point and Time Selection */}
                            <div className={`tw-mx-4 tw-pt-3 ${screen.lg ? 'tw-text-sm' : 'tw-text-xs'}`}>
                                <div className={`tw-border tw-bg-white tw-items-center tw-px-2 tw-py-3 tw-flex hover:tw-bg-gray-bg tw-rounded-t-lg`}
                                    onClick={showDeliveryPointsSelectionPopup}
                                >
                                    <img src={MapMarker} className='tw-mr-1' />
                                    {
                                        selected_delivery_point
                                        ?   <>
                                                <span className='tw-text-xxs tw-mr-3 tw-text-gray-600 tw-tracking-wide'>DELIVERY LOCATION:</span>
                                                <span>{selected_delivery_point.name}</span>
                                            </>
                                        :   <span className='tw-text-gray-600'>Select Delivery Location</span>
                                    }
                                    <FontAwesomeIcon className='tw-ml-auto tw-text-sm tw-text-gray-600' icon={faChevronRight} />
                                </div>
                                {
                                    selected_delivery_point?.delivery_times
                                    ?   <div className="tw-border tw-border-t-0 tw-rounded-b-lg tw-bg-white hover:tw-bg-gray-bg tw-px-2 tw-py-3"
                                                onClick={showDeliveryTimesSelectionPopup}
                                            >
                                                {
                                                    selected_delivery_time_range
                                                    ?   <>
                                                            <div className='tw-flex tw-items-center '>
                                                                <img src={ClockIcon} className='tw-w-[16px] tw-ml-0.5 tw-mr-1' />
                                                                <span className='tw-text-xxs tw-mr-3 tw-text-gray-600 tw-tracking-wide'>DELIVERY TIME:</span>
                                                                <TimeRangeView time_range={selected_delivery_time_range} />
                                                                <FontAwesomeIcon className='tw-ml-auto tw-text-sm tw-text-gray-600' icon={faChevronRight} />
                                                            </div>
                                                            {
                                                                    ordering_closes_within 
                                                                    && ordering_closes_within < 6 * 60 * 60 * 1000
                                                                    && ordering_closes_within > 0
                                                                    ?   <div className='tw-mt-2 tw-px-1 tw--mb-2 tw-border-t tw-border-dashed tw-py-1.5 w3-tiny w3-text-grey'>
                                                                            Ordering closes <DateView millis={selected_delivery_time_range[1] - selected_delivery_point.processing_time} 
                                                                                relative={true} 
                                                                                precision={60000}
                                                                                className={"tw-text-green-600"}
                                                                            />
                                                                        </div>
                                                                    :   null
                                                                }
                                                        </>
                                                    :   <div className='tw-flex tw-items-center '>
                                                            <img src={ClockIcon} className=' tw-mr-1' />
                                                            <span className='tw-text-gray-600'>Select Delivery Time</span>
                                                            <FontAwesomeIcon className='tw-ml-auto tw-text-sm tw-text-gray-600' icon={faChevronRight} />
                                                        </div>
                                                }
                                            </div>
                                    :   null  // don't show default delivery times
                                }                                
                            </div>
                        </div>
                        {is_user_manager
                            ?   <div className='tw-text-xs tw-bg-secondary-lighter tw-text-white tw-py-2 tw-px-4'
                                    onClick={showCustomerSelectPopup}
                                >
                                    {!buying_for_user
                                    ?   <div className='tw-flex tw-gap-3 tw-items-center'>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>Buy for Customer</span>
                                            <span className='tw-ml-auto tw-tracking-wide tw-shrink-0'>SELECT <FontAwesomeIcon icon={faChevronRight} /></span>
                                        </div>
                                    :   <div className='tw-flex tw-gap-3 tw-items-center'>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span className='line-clamp'>Buying for <b>{buying_for_user.name}</b></span>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    }
                                    {buying_for_user?._id 
                                    ?   <div className='tw-flex tw-justify-start tw-mt-2 tw-gap-2'>
                                            <button className='tw-px-2 tw-py-1 tw-rounded tw-bg-yellow-500'
                                                onClick={showRecommendations}
                                            >
                                                <span className='tw-text-black'>Get Recommendations</span>
                                            </button>
                                            <button className='tw-px-2 tw-py-1 tw-rounded tw-bg-yellow-500'
                                                onClick={openNotes}
                                            >
                                                <FontAwesomeIcon icon={faNoteSticky} />&nbsp;&nbsp;<span className='tw-text-black'>Notes</span>
                                            </button>
                                        </div>
                                    :   null
                                    }
                                </div>
                            :   null
                        }

                        <div className='tw-pb-[200px] tw-relative' >
                            {/* Banners */}
                            <div className='tw-mx-2 tw-mt-2'>
                                {
                                    banners?.length > 0 &&
                                    <ReactSwipe
                                        className="carousel"
                                                swipeOptions={{ continuous: true, auto:5000 }}>
                                        {banners.map((banner, i) => (
                                            <div key={i} className='tw-p-2 tw-cursor-pointer'>
                                                <img className='tw-w-full' src={banner.url} alt={i} onClick={()=>{
                                            handleBannerAction(banner)
                                        }}/>
                                            </div>
                                        ))}
                                    </ReactSwipe>
                                }
                            </div>
                            {/* search bar */}
                            <div className={`${stick_search_bar ? "w3-top app-search-bar-sticky w3-2px-shadow tw-bg-white w3-content" : ""} tw-flex tw-items-center tw-justify-between tw-gap-3 tw-px-4 tw-py-2 tw-my-2` }
                                ref={search_bar_ref}
                            >
                                <div className='tw-border tw-rounded-md tw-text-sm tw-px-3 tw-py-2 tw-flex tw-items-center tw-grow'>
                                    <FontAwesomeIcon icon={faSearch} className='tw-text-gray-400' />
                                    <input className='tw-outline-0 tw-ml-3 tw-text-sm tw-grow'
                                        value={search_text}
                                        onChange={
                                            (evt)=> {
                                                setSearchText(evt.target.value);
                                            }
                                        }
                                        placeholder="Search for products"
                                    />
                                    {
                                        search_text
                                        ?   <FontAwesomeIcon icon={faX} className='tw-ml-auto tw-p-3 tw--m-3 tw-text-gray-600 tw-text-xs' onClick={()=>setSearchText("")} />
                                        :   null
                                    }
                                </div>
                                {/* {!stick_search_bar ? <button className='tw-text-secondary-lighter tw-text-xs' onClick={() => setShowAllCategories(true)}>Categories</button> : null} */}
                            </div>
                            {
                                /* Empty space to compensate the search bar height */
                                stick_search_bar
                                ?   <div style={{"height": stick_search_bar + "px"}}></div>
                                :   null
                            }
                            {
                                categories_tree.length && (!search_text || search_text.length < 2)
                                ?   <>
                                        <div className='tw-border-b tw-flex tw-items-center tw-px-4 tw-gap-1 tw-mb-3'>
                                            <div className={`tw-mb-1 tw-shrink-0 tw-flex tw-items-center tw-text-[13px] tw-gap-0.5 tw-cursor-pointer`}
                                                onClick={() => {
                                                    setShowAllCategories(true)
                                                }}>
                                                <img src={FilterIcon} alt="filter" className='tw-w-4' /> <div className='tw-text-gray-600 tw-border-r tw-pr-2'>All</div>
                                            </div>
                                            <div className='tw-flex tw-items-center tw-overflow-x-auto  w3-hide-scrollbar tw-gap-5 tw-pl-3'>
                                                <CategoryBar list={categories_tree} selected={selected_category_tree[0]} setSelected={setSelectedCategory} />
                                            </div>
                                        </div>
                                        {selected_category?.sub_cats?.length || selected_category?.parent 
                                            ? <CategoryChipsBar list={selected_category.sub_cats} selected={selected_category_tree.slice(1)} setSelected={setSelectedCategory} /> 
                                            : null
                                        }
                                    </>
                                :   null
                            }
                            {
                                isConfigEnable(delivery_route.other_config, ORG_SETTINGS["LAST_PURCHASED_MSG_TAG"], true) && !selected_category && last_purchased_users?.length
                                ?   <div className='tw-px-4 tw-pt-6 tw-text-xs tw-text-gray-400 tw-font-bold'>
                                        <FontAwesomeIcon icon={faUsers} className='tw-mr-2' />
                                        Join &nbsp;<span className='tw-text-black'>{last_purchased_users.map((u) => u.name).join(", ")}</span>
                                        &nbsp; for this delivery
                                    </div>
                                : null
                            }
                            {/* products */}
                            <div ref={products_ref} className='tw-grid tw-grid-cols-2 tw-my-8'>
                                {
                                    catalog_products.map(
                                        (cp, i) => {
                                            return <CatalogProduct
                                                key={cp._id}
                                                className={'tw-outline tw-outline-1 tw-outline-gray-200'}
                                                delivery_route={delivery_route}
                                                catalog_product={cp}
                                                buying_for_user={buying_for_user}
                                                order={order}
                                                cart={cart}
                                                grid={!screen.md ? true : false}
                                                image_height={!screen.md ? 165 : null}
                                                onViewProduct={setShowDetailsCp}
                                            />
                                        }
                                    )
                                }
                            </div>
                            {ctx.is_loading_next_results && <LoadingView title={"Loading more products..."} height={100} />}
                            {
                                !ctx.is_loading_next_results && ctx.next_products_cursor && ctx.has_more_products && 
                                 <div className='tw-flex'>
                                 <button className='tw-mx-auto tw-text-sm tw-border tw-border-secondary tw-px-4 tw-py-2 tw-rounded tw-text-secondary' onClick={loadMoreProducts}>LOAD MORE</button>
                                 </div>
                            }
                            <div className='w3-super-tiny w3-text-grey tw-px-4 tw-mt-2'>
                                Build: {window.BUILD_CODE}<br/>
                                Time: {process.env.REACT_APP_BUILD_VERSION}
                            </div>
                        </div>
                    </div>
                    <div className='w3-bottom w3-2px-shadow w3-light-grey tw-z-10'>
                        <div>                    
                            {
                                minimum_for_home_delivery
                                ?   <div className='tw-bg-emerald-600 tw-px-4 tw-py-1 tw-text-gray-100 tw-text-xs'>
                                        Delivery from delivery point @{delivery_route.currency_symbol}
                                        &nbsp;{minimum_for_home_delivery / 1000}
                                    </div>
                                :   null
                            }
                            { /* OTHER ORDERS IF ANY */
                                all_orders.map(
                                    (_order) => {
                                        const can_modify = canModifyOrder(_order);
                                        return <TinyOrderStrip can_modify={can_modify} key={_order._id}
                                            order={_order}
                                            onClick={() => setReviewCart({order: _order, cart: can_modify ? cart : {}, title: `Order ${_order._id}`})}
                                        />
                                    }
                                )
                            }
                        </div>
                        <div className='tw-px-4 tw-py-2'>
                            <CartShortView
                                delivery_route={delivery_route}
                                buying_for_user={buying_for_user}
                                user={user}
                                order={order}
                                cart={cart}
                                popupCartView={() => setReviewCart({cart, order})}
                            />
                        </div>
                    </div>
                    <ResponsivePopup show={!!review_cart} onClose={() => setReviewCart(null)} is_full_screen={true} title={review_cart?.title || 'Review Cart'} style_props={{md_max_width: '60%'}}>
                        <CartAndOrderView
                            delivery_route={delivery_route}
                            buying_for_user={buying_for_user}
                            order={review_cart?.order}
                            cart={review_cart?.cart}
                            user={user}
                            is_from_store={true}
                            onDeliveryPointSelect={showDeliveryPointsSelectionPopup}
                            onDeliveryTimeSelect={showDeliveryTimesSelectionPopup}
                            onViewProduct={setShowDetailsCp}
                            onRefresh={fetchInit}
                        />
                    </ResponsivePopup>
                    <ResponsivePopup show={show_details_cp && delivery_route} 
                        onClose={() => setShowDetailsCp(null)}
                        is_full_screen={true}
                        is_push_state={true}
                    >
                        <ProductDetails catalog_product={show_details_cp} delivery_route={delivery_route}
                            cart={cart} order={order}
                            user={user}
                            buying_for_user={buying_for_user}
                            onContinueShopping={() => setShowDetailsCp(null)}
                        />
                    </ResponsivePopup>
                    <ResponsivePopup show={show_recommendations} onClose={() => setShowRecommendations(false)} title={'Recommended for ' + buying_for_user?.name }>
                        {
                            recommended_products?.length
                            ?   <div className='tw-pb-14 tw-grid md:tw-grid-cols-2'>
                                    {
                                        recommended_products.map(
                                            (cp) => {
                                                return <CatalogProduct
                                                    delivery_route={delivery_route}
                                                    className={'tw-outline tw-outline-1 tw-outline-gray-200'}
                                                    catalog_product={cp}
                                                    key={cp._id}
                                                    order={order}
                                                    buying_for_user={buying_for_user}
                                                    cart={cart}
                                                    onViewProduct={setShowDetailsCp}
                                                />
                                            }
                                        )
                                    }
                                    <div className='tw-px-4 tw-absolute tw-bottom-0 tw-w-full tw-bg-white tw-py-2'>
                                        <button className='tw-text-sm tw-bg-secondary-lighter tw-px-4 tw-py-2 tw-w-full tw-rounded tw-text-white'
                                            onClick={addAllRecommendedToCart}
                                        >
                                            Add all to Cart
                                        </button>
                                    </div>
                                </div>
                            :   is_recomm_loading ? <LoadingView height={200} /> : <NotFoundView title={"No recommendations found for " + buying_for_user?.name} height="300px" />
                        }
                    </ResponsivePopup>
                    <ResponsivePopup show={show_all_categories} onClose={() => {
                        setShowAllCategories(false)
                    }} title={'Categories'}>
                        <CategoriesPopup list={categories_tree} selected={selected_category} onApply={(c, skip_close) => {setSelectedCategory(c); !skip_close && setShowAllCategories(false)}} />
                    </ResponsivePopup>
                </>
            }
        </PerformanceMonitor>
    )
}

const TinyOrderStrip = ({order, can_modify, onClick}) => {
    return (
        <div className={`tw-px-4 tw-py-1 tw-text-gray-100 tw-border-t tw-border-slate-600 tw-text-xs ${can_modify ? 'tw-bg-emerald-600' : 'tw-bg-slate-500'}`}
            onClick={onClick}
        >
            <span># {order._id}&nbsp;</span>
            {
                order.status !== ORDER_STATUS_DELIVERED
                ?   <span>
                        delivery at &nbsp;
                        <DateView millis={order.delivery_time} />
                    </span> 
                :   <span>
                        was delivered
                    </span>
            }
        </div>
    )
}

function CartShortView({delivery_route, order, cart, user, buying_for_user, popupCartView}){
    /* merge cart items and order */
    const {total_amount, total_saving, items_total, cp_ids} = getCartTotal(order, cart);
    const now_millis = new Date().getTime();
    const [show_past_orders, setShowPastOrders] = useState(false);

    return (
        <div className='tw-flex tw-gap-4'>
            <div className='tw-shrink-0'
                onClick={popupCartView}
            >
                {
                    cp_ids && cp_ids.length
                    ?    <div className='tw-text-xxs tw-mb-1 tw-text-gray-600'>
                            {cp_ids.length} Products
                        </div>
                    :   null
                }
                <div>
                    <span className='tw-text-md tw-font-bold tw-tracking-wide'>
                        {getDisplayPrice(delivery_route.currency, total_amount)} 
                    </span>
                    {
                        total_saving && total_saving > 100
                        ?   <div className='tw-text-xs tw-text-green-600'>
                                You save {getDisplayPrice(delivery_route.currency, total_saving)}
                            </div>
                        :   null
                    }
                </div>
            </div>
            <div className='tw-ml-auto w3-flex w3-flex-vcenter w3-flex-hcenter w3-center'>
                {delivery_route.is_ordering_frozen
                    ?   delivery_route.has_future_delivery_slots
                        ?   <div className="tw-text-red-600 tw-text-xs">
                                Delivery closed for this slot, pick a new delivery slot
                            </div>
                        :   <NoSlotsShortView user={user} />
                    :   !order?._id
                    ?   <button className='tw-bg-secondary-lighter tw-rounded-md tw-px-6 tw-py-2 tw-text-white tw-text-sm' onClick={() => {
                            popupCartView()
                        }}>
                            View Cart
                        </button>
                    :   <div className='w3-center'>
                            <div className='tw-bg-secondary-lighter tw-rounded-md tw-px-6 tw-py-2 tw-text-white tw-text-sm'
                                onClick={popupCartView}
                            >
                                Modify Order
                            </div>
                            <div className='tw-text-xxs tw-text-gray-600 tw-mt-[2px]'>
                                You can modify until <CountDownView millis={order.can_modify_until - now_millis} />
                            </div>
                        </div>
            
                }
                {
                    user || buying_for_user  /* past orders */
                    ?   <div className='tw-text-center tw-py-2 tw-rounded tw-text-sm' onClick={() => setShowPastOrders(true)}>
                            <FontAwesomeIcon icon={faHistory} className='tw-ml-4' />
                        </div>
                    : null
                }             
            </div>
            <ResponsivePopup title={`${buying_for_user ? buying_for_user?._id + "'s" : 'Your'} Past Orders`}
                show={show_past_orders} onClose={() => setShowPastOrders(false)}
            >
                <UserOrders
                    delivery_route={delivery_route}
                    buying_for_user={buying_for_user}
                    user={user}
                />
            </ResponsivePopup>
        </div>
    );
}
const showWAOrderPopUp = (delivery_route) => {
    var popup = Popup.slideUp(
        <WAOrderPopUp delivery_route={delivery_route} onContinue={() => {
            popup.close();
        }}
            onWhatsApp={() => {
                openWhatsapp(delivery_route.delivery_org.wa_business_number, `I would love explore collections at ${delivery_route.delivery_org?.title} and shop.`)
                popup.close()
            }} />, {});
}
  
export default App;
