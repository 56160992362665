import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { track_event } from "../../analytics";
import IC_PRICING_GROW from "../../images/landing/bookdemo/ic_pricing_grow.svg";
import IC_PRICING_PRO from "../../images/landing/bookdemo/ic_pricing_pro.svg";
import IC_PRICING_STARTER from "../../images/landing/bookdemo/ic_pricing_starter.svg";
import bgPage1 from "../../images/landing/page1_bg.svg";

export function PagePricing({className, SpeakToSpecialistButton, org_id}) {
  const pricing = [
    {
      icon: IC_PRICING_STARTER,
      title: "Starter",
      desc: "For small businesses just getting started\non conversational commerce.",
      offer_price: "$69.99",
      actual_price: "$149",
      demo_link: "",
      included: "What’s included",
      features: [
        "3 users, additional users $20/month",
        "300 free transactions",
        "1000 free contacts",
        "Limited AI flow-builder bots",
        "Basic segmentation (10 tags)",
        "Basic Analytics",
        "WooCommerce/Shopify/Magento\nIntegrations",
        "Select Payment Integrations",
        "Email Support +2 Calls/Month"
      ]
    },
    {
      icon: IC_PRICING_GROW,
      title: "Growth",
      desc: "For growing businesses looking to\nautomate workflows.",
      offer_price: "$299",
      actual_price: "$349",
      demo_link: "",
      included: "What’s included",
      features: [
        "10 users, additional users $30/month",
        "1000 free transactions",
        "5000 free contacts",
        "10 AI flow-builder bots",
        "Custom + AI segmentation",
        "Advanced Analytics",
        "Additional E COMMERCE/CRM\nintegrations",
        "Additional Payment Integrations",
        "Shared Customer Success Manager"
      ]
    },
    {
      icon: IC_PRICING_PRO,
      title: "Pro",
      desc: "For businesses that need tailormade\nsolutions.",
      price_desc: "We'll customise it for you.\nLet's talk.",
      actual_price: "",
      demo_link: "",
      included: "Everything in Starter plan PLUS",
      features: [
        "Custom number of users",
        "Custom transaction fees",
        "Custom Contacts",
        "Unlimited AI flow-builder bots",
        "Custom AI Segmentation",
        "Custom ERP Integrations",
        "Custom E-commerce Integrations",
        "Custom Payment Integrations",
        "Dedicated Customer Success\nManager",
        "Phone & Email Support Manager"
      ]
    },
  ]
  return <div id="div_pricing" className={`tw-p-8 md:tw-px-16 tw-text-center ${className}`}>
    
    <div className="tw-flex tw-flex-wrap tw-justify-center tw-mt-8 md:tw-mt-12 tw-gap-8 md:tw-gap-12 tw-text-start ">
      {
        pricing.map((pricing, i) => {
          return (
            <div key={pricing.title} className={` tw-bg-white tw-border tw-rounded-xl tw-border-[#E1E1E1] tw-p-4 sm:tw-w-[314px] ${
            pricing?.title === "Growth" 
              ? "tw-border-[2px] tw-border-primary-v2"
              : ""
            }`}>
              <div className="tw-flex tw-items-center tw-gap-4 tw-text-[16px] md:tw-text-[20px] tw-font-semibold tw-text-black tw-mb-1">
                <img src={pricing.icon} alt={pricing.title} /> {pricing.title}
              </div>
              <div className="tw-whitespace-pre tw-items-center tw-mt-2 tw-gap-4 tw-text-[12px] md:tw-text-[14px] tw-font-light tw-text-[#6F6C90]">
                <div>{pricing.desc}</div>
                {
                  pricing.offer_price
                    ? <div className="tw-mt-2 tw-text-[24px] md:tw-text-[42px] tw-font-semibold tw-text-black tw-ml-2">
                      {pricing.offer_price}
                      <span className="tw-text-[18px] md:tw-text-[36] tw-text-[#6F6C90]">/month</span>
                      <span className="tw-ml-4 tw-line-through tw-text-[20px] md:tw-text-[24] tw-text-[#6F6C90]">{pricing.actual_price}</span>
                    </div>
                    : <div className="tw-mt-3 tw-text-[22px] lg:tw-text-[20px] tw-font-semibold tw-text-black sm:tw-mx-3">
                      {pricing.price_desc}
                    </div>
                }
                {
                  SpeakToSpecialistButton 
                  ? <SpeakToSpecialistButton org_id={org_id} selected_plan={pricing.title} /> 
                  : <button type="button" onClick={() => {
                    track_event("button_schedule_demo")
                    window.open(`/contactus?form=${pricing.title}`, "_self")
                  }}
                    className="max-md:tw-flex-1 tw-w-full tw-rounded-full tw-bg-landing-green tw-px-6 md:tw-px-12 tw-text-white tw-py-3.5 tw-my-4">
                    Schedule a Demo
                  </button>
                }
                <div className="tw-text-[#170F49] tw-font-bold tw-text-[14px] md:tw-text-[16px]">
                  {pricing.included}
                  {
                    pricing.features.map((feature, j) => {
                      return <div key={j} className=" tw-flex tw-gap-2 tw-text-[14px] md:tw-text-[16px] tw-font-normal tw-text-[#170F49] tw-mt-2">
                        <FontAwesomeIcon icon={faChevronRight} className="tw-mt-1" />
                        <div >{feature}</div>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  </div>
}

function PricingPage() {
  return (
    <div className=" tw-scroll-smooth tw-scrollbar-none font-figtree"
      style={{ backgroundImage: `url(${bgPage1})`, backgroundSize: 'cover' }}>
      <div
        id="home"
        className="tw-relative tw-px-6 tw-pt-6">

        <div className="lg:tw-flex md:tw-justify-center max-lg:tw-gap-8 lg:tw-gap-12 tw-items-center">
          <div className="tw-text-[#333333] tw-font-semibold tw-text-center">
            <div className="tw-text-[32px] lg:tw-text-[48px] xl:tw-text-[56px] tw-leading-tight">
              Suitable Pricing for Every Business
            </div>
            <div className="lg:tw-whitespace-pre tw-mt-4 tw-font-light md:tw-text-[22px] max-md:tw-text-[18px] ">
              {"Whether you're a large enterprise, a growing business, or just starting out, Sukhiba has you covered. Our\npricing plans are designed to fit your needs and scale with your growth. Enjoy tailored support and\nflexible solutions as your business evolves."}</div>
          </div>
        </div>
      </div>
      <PagePricing />
    </div>
  );
}

export default PricingPage;
